<template>
  <DetailTemplate
    :custom-class="'ticket-detail detail-page'"
    v-if="getPermission('delivery:view')"
  >
    <template v-slot:sidebar-detail>
      <div>
        <v-chip
          class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
          :color="delivery?.status_relation?.color"
          label
          :class="
            delivery?.status_relation?.text == 'En-Route' ? '' : 'text-white'
          "
        >
          <template
            v-if="delivery?.status == 7 && delivery?.type == 'incoming'"
          >
            Returned
          </template>
          <template
            v-else-if="delivery?.status == 7 && delivery?.type == 'transfer'"
          >
            Transferred
          </template>
          <template v-else>
            {{ delivery?.status_relation?.text }}
          </template>
        </v-chip>

        <v-chip
          :class="delivery?.type == 'transfer' ? '' : 'text-white'"
          class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
          :color="getColor()"
          label
        >
          <v-icon> {{ getIcon() }}</v-icon> {{ delivery.type }}
        </v-chip>

        <v-chip
          v-if="delivery?.is_exchange == 1"
          class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
          label
          :color="'red'"
          outlined
          text-color=""
        >
          Exchange
        </v-chip>
        <v-icon
          v-if="delivery?.is_exchange == 1"
          color="red"
          size="30"
          :disabled="delivery?.is_exchange_note == 1 ? false : true"
          >mdi-sync</v-icon
        >
        <v-chip
          v-if="delivery?.is_repair == 1"
          class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
          label
          :color="'red'"
          outlined
          text-color=""
        >
          Repair
        </v-chip>
      </div>
      <div class="mt-5">
        <table width="100%">
          <tr>
            <td
              valign="top"
              colspan="2"
              class="py-1 font-size-18 font-weight-600"
            >
              <div class="d-flex">
                <p class="m-0" v-if="delivery?.type != 'transfer'">
                  <span class="d-block">
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="
                        delivery?.customer_type == 'customer-list'
                          ? 'blue'
                          : 'red'
                      "
                      small
                    >
                      <template
                        v-if="delivery?.customer_type == 'customer-list'"
                      >
                        Customer
                      </template>
                      <template v-else> Supplier </template>
                    </v-chip>
                  </span>
                </p>
                <p class="m-0" v-if="delivery?.type == 'transfer'">
                  <span class="d-block">
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="
                        delivery?.customer_type == 'customer-list'
                          ? 'blue'
                          : 'red'
                      "
                      small
                    >
                      <template
                        v-if="delivery?.customer_type == 'customer-list'"
                      >
                        Customer
                      </template>
                      <template v-else> Supplier </template>
                    </v-chip>
                    <v-icon>mdi-arrow-right</v-icon>
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="
                        delivery?.to_customer_type == 'customer-list'
                          ? 'blue'
                          : 'red'
                      "
                      small
                    >
                      <template
                        v-if="delivery?.to_customer_type == 'customer-list'"
                      >
                        Customer
                      </template>
                      <template v-else> Supplier </template>
                    </v-chip>
                  </span>
                </p>
                <p
                  class="m-0 ml-34"
                  v-if="delivery?.delivery_converted?.barcode"
                >
                  <span
                    class="d-block"
                    v-if="
                      delivery.duplicateType == 'rn-do' &&
                      delivery?.delivery_converted?.barcode
                    "
                  >
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="'blue'"
                      small
                    >
                      RN
                    </v-chip>
                    <v-icon>mdi-arrow-right</v-icon>
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="'orange'"
                      small
                    >
                      DO
                    </v-chip>
                  </span>
                  <span
                    class="d-block"
                    v-if="
                      delivery.duplicateType == 'do-rn' &&
                      delivery?.delivery_converted?.barcode
                    "
                  >
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="'orange'"
                      small
                    >
                      DO
                    </v-chip>
                    <v-icon>mdi-arrow-right</v-icon>
                    <v-chip
                      class="text-white text-capitalize"
                      label
                      :color="'blue'"
                      small
                    >
                      RN
                    </v-chip>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td
              valign="top"
              width="40%"
              class="py-1 font-size-18 font-weight-600"
            >
              Delivery #
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500"
              width="60%"
            >
              <label class="m-0" v-if="delivery && delivery.barcode">
                <Barcode :barcode="delivery.barcode"></Barcode>
              </label>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              <template v-if="delivery?.customer_type == 'customer-list'">
                <template v-if="delivery?.type == 'transfer'">From</template>
                Customer
              </template>
              <template v-else>
                <template v-if="delivery?.type == 'transfer'">From</template>
                Supplier
              </template>
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 d-flex"
            >
              <span
                style="color: #24326d !important"
                class="custom-read-more font-weight-600 font-size-18"
              >
                <v-chip
                  v-on:click="toRoute()"
                  small
                  color="blue darken-4"
                  class="cursor-pointer"
                  outlined
                  label
                >
                  {{ delivery?.customer_relation?.display_name }}
                </v-chip>
              </span>
            </td>
          </tr>
          <tr v-if="delivery?.type == 'transfer'">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              <template v-if="delivery?.to_customer_type == 'customer-list'">
                To Customer
              </template>
              <template v-else>To Supplier </template>
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 d-flex"
            >
              <span
                style="color: #24326d !important"
                class="custom-read-more font-weight-600 font-size-18"
              >
                <v-chip
                  v-on:click="toRoute()"
                  small
                  color="blue darken-4"
                  class="cursor-pointer"
                  outlined
                  label
                >
                  {{ delivery?.to_customer_relation?.display_name }}
                </v-chip>
              </span>
            </td>
          </tr>
          <tr
            v-if="
              delivery?.rental_id ||
              delivery?.sale_id ||
              delivery?.visit_id > 0 ||
              delivery?.delivery_converted?.id > 0
            "
          >
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Converted By
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <v-chip
                v-if="delivery?.is_visit && delivery?.visit_id > 0"
                label
                color="red"
                outlined
                text-color=""
                class="custom-barcode p-3"
                v-on:click.stop.prevent="
                  $router.push(
                    getDefaultRoute(`visit.detail`, {
                      params: { id: delivery.visit_id },
                    })
                  )
                "
              >
                {{ delivery?.visit_barcode }}
              </v-chip>

              <v-chip
                v-if="delivery?.is_sell && delivery?.sale_id > 0"
                label
                color="blue"
                outlined
                text-color=""
                class="custom-barcode p-3"
                v-on:click.stop.prevent="
                  $router.push(
                    getDefaultRoute(`sell.detail`, {
                      params: { id: delivery.sale_id },
                    })
                  )
                "
              >
                {{ delivery?.sale_barcode }}
              </v-chip>

              <v-chip
                v-if="delivery?.is_rental && delivery?.rental_id > 0"
                label
                color="orange"
                outlined
                text-color=""
                class="custom-barcode p-3"
                v-on:click.stop.prevent="
                  $router.push(
                    getDefaultRoute(`rental.detail`, {
                      params: { id: delivery.rental_id },
                    })
                  )
                "
              >
                {{ delivery?.rental_barcode }}
              </v-chip>
              <v-chip
                v-if="delivery?.delivery_converted?.id > 0"
                label
                color="green"
                outlined
                text-color=""
                class="custom-barcode p-3"
              >
                {{ delivery?.delivery_converted?.barcode }}
              </v-chip>
              <!--   v-on:click.stop.prevent="
                  detailDelivery(delivery?.delivery_converted)
                " -->
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Delivery Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <span style="color: #24326d !important">
                {{ formatDate(delivery.started_at) }}
              </span>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Delivery Time
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <span style="color: #24326d !important">
                {{ formatDateTimeStart(delivery.started_at) }} -
                {{ formatetimedata(delivery.finished_at) }}
              </span>
            </td>
          </tr>
          <tr v-if="false">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Payment Type
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0" v-if="delivery && delivery.payment_type">
                <template v-if="delivery?.payment_type == 'cash-on-delivery'">
                  <v-chip
                    outlined
                    label
                    style="height: 26px"
                    class="mr-2"
                    color="green white--text"
                  >
                    Cash On Delivery
                  </v-chip>
                </template>
                <template v-if="delivery?.payment_type == 'prepaid'">
                  <v-chip
                    outlined
                    style="height: 26px"
                    class="mr-2"
                    color="cyan white--text"
                  >
                    Prepaid
                  </v-chip></template
                >
                <template v-if="delivery?.payment_type == 'postpaid'">
                  <v-chip
                    outlined
                    style="height: 26px"
                    class="mr-2"
                    color="red white--text"
                  >
                    Postpaid
                  </v-chip></template
                >
              </label>
            </td>
          </tr>
          <tr v-if="false">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Payment Mode
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label
                class="m-0"
                v-if="delivery && delivery?.payment_mode_relation?.text"
              >
                <v-chip color="red darken-4" label small outlined text-color="">
                  {{ delivery?.payment_mode_relation?.text }}</v-chip
                >
              </label>
              <em v-else class="text-muted"> no payment mode </em>
            </td>
          </tr>
          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Reference #
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label
                class="m-0 text-capitalize"
                v-if="delivery && delivery.reference"
                >{{ delivery.reference }}</label
              >
              <em v-else class="text-muted"> no refernce </em>
            </td>
          </tr>
          <!--   <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Old Delivery
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label
                class="m-0 text-capitalize"
                v-if="delivery && delivery.old_do"
                >{{ delivery.old_do }}</label
              >
              <em v-else class="text-muted"> no old delivery </em>
            </td>
          </tr> -->
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Priority
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <Priority
                v-if="delivery && delivery.priority"
                class="my-1 ml-n2"
                :priority="delivery.priority"
                small
              ></Priority>
            </td>
          </tr>
          <tr v-if="delivery?.order_no">
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Order Of Del.
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 text-capitalize"
            >
              <v-chip
                v-if="delivery.order_no"
                class="font-weight-700"
                small
                color="black"
                label
                text-color=""
                outlined
              >
                {{ delivery.order_no }}
              </v-chip>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="py-1 font-size-18 font-weight-600">
              Key Given
            </td>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500 text-capitalize"
            >
              <template v-if="delivery.key_given">
                <v-chip
                  small
                  label
                  class="text-white"
                  :color="delivery.key_given == 'yes' ? 'green' : 'red'"
                >
                  {{ delivery.key_given }}
                </v-chip>
              </template>
              <em v-else class="text-muted"> no key given </em>
            </td>
          </tr>

          <tr>
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Machine(s) delivered on
            </td>
            <td
              valign="top"
              class="py-1 font-size-18 font-weight-500 text-capitalize"
            >
              <template v-if="delivery.machine_delivered_on">
                {{ formatDate(delivery.machine_delivered_on) }}
              </template>
              <em v-else class="text-muted"> no machine(s) delivered on</em>
            </td>
          </tr>

          <!-- <tr v-if="delivery.actual_start_date">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Actual Job Start Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">
                <v-chip small outlined color="red" label>
                  {{ formatDate(delivery.actual_start_date) }}
                  {{ formatDateStartTime(delivery.actual_start_date) }}
                </v-chip>
              </label>
            </td>
          </tr>
          <tr v-if="delivery.actual_end_date">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Actual Job End Date
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">
                <v-chip small outlined color="green" label>
                  {{ formatDate(delivery.actual_end_date) }}
                  {{ formatDateStartTime(delivery.actual_end_date) }}
                </v-chip>
              </label>
            </td>
          </tr> -->
          <!--   <tr v-if="detail.schedule_message">
            <td valign="top" class="py-1 font-size-18 font-weight-600">
              Schedule
            </td>
            <td valign="middle" class="py-1 font-size-18 font-weight-500">
              <label class="m-0">{{ detail.schedule_message }}</label>
            </td>
          </tr> -->
          <!--  <tr v-if="visit.cancel_reason">
              <td valign="top" class="py-1 font-size-18 font-weight-600 d-flex">
                Cancel Reason
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ visit.cancel_reason }}</label>
              </td>
            </tr> -->
          <tr class="mb-1">
            <td colspan="2" class="p-0">
              <table width="100%">
                <tr>
                  <th
                    colspan="2"
                    class="grey lighten-4 font-size-19 color-custom-blue py-1"
                  >
                    Diesel Level
                  </th>
                </tr>
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500 d-flex p-0"
                  >
                    <template v-if="delivery?.diesel_level?.length > 0">
                      <template
                        v-for="(diesel_level, index) in delivery.diesel_level"
                      >
                        <v-checkbox
                          class="pointer-events-none"
                          :key="index"
                          color="cyan white--text"
                          :label="diesel_level.title"
                          v-model="diesel_level.is_active"
                          readonly
                        />
                      </template>
                    </template>
                    <em v-else class="text-muted ml-2"> no Diesel Level</em>
                  </td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <th class="grey lighten-4 font-size-19 color-custom-blue py-1">
              Tags
            </th>
            <th class="grey lighten-4 font-size-19 color-custom-blue py-1"></th>
          </tr>

          <tr>
            <td
              valign="middle"
              class="py-1 font-size-18 font-weight-500"
              colspan="2"
            >
              <template v-if="delivery.tags && delivery.tags?.length">
                <v-chip
                  v-for="(row, index) in delivery.tags"
                  :key="index"
                  class="mr-1 mb-1 text-capitalize"
                  text-color="white"
                  small
                  label
                  :color="row.color"
                >
                  <span class="">
                    {{ row.text }}
                  </span>
                </v-chip>
              </template>
              <em v-else class="text-muted"> no tags</em>
            </td>
          </tr>
          <template v-if="delivery?.accepted_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Accepted remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.accepted_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.rejected_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Rejected Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.rejected_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.pickup_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Pickup Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.pickup_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.exchane_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Exchange Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.exchane_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.hold_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Hold Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.hold_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.dropoff_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                DropOff Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.dropoff_remark }}
              </td>
            </tr>
          </template>
          <template v-if="delivery?.failed_remark">
            <tr>
              <th class="grey lighten-4 color-custom-blue py-1" colspan="2">
                Failed Remark
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 text-capitalize"
                colspan="2"
              >
                {{ delivery?.failed_remark }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </template>
    <template v-slot:header-title>
      <!-- <v-chip color="blue darken-4" outlined label> -->
      <span
        class="color-custom-blue font-weight-700 font-size-26"
        v-if="delivery && delivery.title"
      >
        {{ delivery.title }}
      </span>
      <!-- </v-chip> -->
    </template>

    <template v-slot:header-action>
      <div class="d-flex align-center justify-end flex-wrap" v-if="pageLoaded">
        <template v-if="delivery?.status == 7 && delivery?.type == 'incoming'">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="pushToDelivery('convert_to_outgoing')"
              >
                <v-icon left>mdi-plus</v-icon> Delivery
              </v-btn>
            </template>
            <span>Convert To Outgoing</span>
          </v-tooltip>
        </template>
        <template v-if="delivery?.status == 7 && delivery?.type == 'outgoing'">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="pushToDelivery('convert_to_incoming')"
              >
                <v-icon left>mdi-plus</v-icon> Return
              </v-btn>
            </template>
            <span>Convert To Incoming</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            (delivery?.status == 1 || delivery?.status == 11) &&
            delivery?.is_exchange_note == 0
          "
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_delete')"
              >
                <v-icon left>mdi-delete</v-icon> Delete
              </v-btn>
            </template>
            <span>Delivery Delete</span>
          </v-tooltip>
        </template>
        <template>
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="blue"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('convert_to_visit')"
              >
                <v-icon left>mdi-truck</v-icon> Convert To Visit
              </v-btn>
            </template>
            <span>Convert To Visit</span>
          </v-tooltip>
        </template>
        <template v-if="delivery?.status == 1 || delivery?.status == 11">
          <!-- <v-menu
            transition="slide-y-transition"
            bottom
            content-class="custom-menu-list"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
              >
                More... <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-for="(more, index) in moreActions">
                <v-list-item
                  v-if="more.action"
                  link
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="statusLoading || more.disabled"
                >
                  <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                    <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-500 font-size-14">{{
                    more.title
                  }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <v-btn
            depressed
            class="mx-2 mb-1 custom-bold-button white--text"
            color="red"
            @click="updateMoreAction('mark_as_cancel')"
          >
            <v-icon style="font-size: 18px; line-height: 18px"
              >mdi-cancel</v-icon
            >
            Mark as Cancel
          </v-btn> -->

          <!--   <v-btn
            depressed
            class="mx-2 mb-1 custom-bold-button white--text"
            color="red"
            v-on:click="deleteVisitDialog = true"
          >
            <v-icon style="font-size: 18px; line-height: 18px"
              >mdi-delete</v-icon
            >
            Delete
          </v-btn> -->

          <v-btn
            v-if="delivery?.is_exchange_note == 0"
            depressed
            class="mx-2 mb-1 custom-bold-button white--text"
            color="cyan"
            v-on:click="pushToUpdate"
          >
            <v-icon>mdi-pencil </v-icon>
          </v-btn>
        </template>

        <v-tooltip
          bottom
          content-class="custom-bottom-tooltip"
          v-if="delivery?.status == 8"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 mb-1 custom-bold-button white--text"
              color="red darken-4"
              :disabled="visitLoading"
              @click="updateMoreAction('mark_as_forceclosed')"
            >
              <v-icon color="white darken-4" left>mdi-close-circle</v-icon>
              Force Close
            </v-btn>
          </template>
          <span>Force Close</span>
        </v-tooltip>

        <template v-if="engineerList?.length">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assigned_team_new"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span>
                          </v-tooltip>
                          <v-list-item-subtitle
                            v-if="lodash.isEmpty(team.designation) == false"
                            >{{ team.designation.value }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <v-btn
          depressed
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <v-container fluid class="">
        <v-row v-if="pageLoaded">
          <v-col md="12" class="py-0">
            <v-tabs
              active-class="custom-tab-active"
              v-model="deliveryTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
              show-arrows
            >
              <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Line Items
              </v-tab>
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#deliveries"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <span style="text-transform: none !important">ePOD</span>/
                Deliveries
              </v-tab>
              <v-tab class="font-size-16 font-weight-600 px-8" href="#team">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/engineer.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Drivers
              </v-tab>
              <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
                  <!--end::Svg Icon-->
                </span>

                Visits
              </v-tab>

              <v-tab
                class="font-size-16 font-weight-600 px-8"
                href="#attachment"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Attachments
              </v-tab>

              <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/history.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                History
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="deliveryTab">
              <v-tab-item value="line-item">
                <VisitLineItem
                  :show-price="delivery.show_price"
                  :discount-level="
                    delivery.discount_type == 2 ? 'line_item' : 'transaction'
                  "
                  :related-id="deliveryId"
                  :related-type="10"
                  :related-data="delivery"
                />
                <div
                  class="mt-4 pr-2 font-weight-600"
                  v-if="delivery?.type != 'incoming'"
                >
                  <table width="100%">
                    <tr>
                      <td width="66%"></td>
                      <td width="18%" align="right">Sub Total</td>
                      <td width="18%" align="right">
                        {{ formatMoney(delivery.sub_total) }}
                      </td>
                    </tr>
                    <tr v-if="delivery.discount_type == 1">
                      <td></td>
                      <td align="right">
                        Discount
                        <span v-if="delivery.discount_value_type == 1"
                          >({{ formatMoney(delivery.discount_value) }})</span
                        >
                        <span v-if="delivery.discount_value_type == 2"
                          >({{ formatNumber(delivery.discount_value) }}%)</span
                        >
                      </td>
                      <td align="right">
                        {{ formatMoney(delivery.discount_amount) }}
                      </td>
                    </tr>
                    <tr v-if="delivery.tax_applied">
                      <td></td>
                      <td align="right">Tax [GST {{ delivery.tax_value }}%]</td>
                      <td align="right">
                        {{ formatMoney(delivery.tax_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td align="right">Adjustment</td>
                      <td align="right">
                        {{ formatMoney(delivery.adjustment) }}
                      </td>
                    </tr>
                    <tr class="font-size-19">
                      <td></td>
                      <td align="right">Grand Total</td>
                      <td align="right">{{ formatMoney(delivery.total) }}</td>
                    </tr>
                  </table>
                </div>
              </v-tab-item>
              <v-tab-item value="deliveries">
                <DeliveriesTab :parent-detail="delivery"></DeliveriesTab>
              </v-tab-item>
              <v-tab-item
                v-if="getPermission('equipment:view')"
                :value="'visit'"
              >
                <VisitListingTemplate
                  is-admin
                  visit-type="all"
                  :visit-status="0"
                  detailRoute="visit.detail"
                  internal
                  :delivery-id="deliveryId"
                ></VisitListingTemplate>
              </v-tab-item>
              <v-tab-item value="other">
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col md="12">
                      <v-card
                        flat
                        class="custom-grey-border remove-border-radius mb-4 h-100"
                      >
                        <v-card-title class="headline grey lighten-4">
                          <span
                            class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >
                            Information</span
                          >
                        </v-card-title>
                        <v-card-text class="p-6 font-size-16">
                          <!--  <v-flex md12> 
                              
                          </v-flex> -->
                          <v-row class="pl-1">
                            <v-col
                              :md="delivery?.type == 'transfer' ? '3' : '4'"
                              class="bgfrom"
                            >
                              <v-layout class="align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading">
                                    <template
                                      v-if="delivery?.type == 'transfer'"
                                    >
                                      From </template
                                    >Billing Location
                                    <template
                                      v-if="
                                        delivery?.billing_relation
                                          ?.withcompany == 1
                                      "
                                    >
                                      <v-chip
                                        label
                                        small
                                        class="ml-1"
                                        color="red"
                                        outlined
                                        style="
                                          min-height: 24px;
                                          font-size: 13px;
                                        "
                                      >
                                        A&G
                                      </v-chip></template
                                    >
                                    <template
                                      v-if="
                                        delivery?.select_billing_type ==
                                          'from_customer_billing' &&
                                        delivery?.type == 'transfer'
                                      "
                                    >
                                      <v-chip
                                        label
                                        small
                                        class="ml-1 text-white"
                                        color="green"
                                        style="
                                          min-height: 24px;
                                          font-size: 13px;
                                        "
                                      >
                                        Billing
                                      </v-chip></template
                                    >
                                  </label></v-flex
                                >
                              </v-layout>
                              <template v-if="delivery?.billing_relation?.id">
                                <span
                                  v-if="
                                    delivery?.billing_relation &&
                                    delivery?.billing_relation.property_address
                                  "
                                >
                                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                                >
                                <p
                                  class="text-capitalize"
                                  v-if="
                                    delivery?.billing_relation &&
                                    delivery?.billing_relation.property_address
                                  "
                                >
                                  <template
                                    v-if="
                                      delivery?.billing_relation?.property_name
                                    "
                                  >
                                    {{
                                      delivery?.billing_relation?.property_name
                                    }}<br />
                                  </template>
                                  {{ delivery?.billing_relation.street_1 }},
                                  <br
                                    v-if="
                                      delivery?.billing_relation?.street_2 ||
                                      delivery?.billing_relation?.unit_no
                                    "
                                  />
                                  <template
                                    v-if="delivery?.billing_relation?.street_2"
                                  >
                                    {{ delivery?.billing_relation?.street_2 }},
                                  </template>
                                  <template
                                    v-if="delivery?.billing_relation?.unit_no"
                                  >
                                    {{ delivery?.billing_relation?.unit_no }},
                                  </template>
                                  <br />
                                  {{ delivery?.billing_relation.country }}
                                  <template
                                    v-if="
                                      delivery?.billing_relation &&
                                      delivery?.billing_relation.zip_code !=
                                        '000000'
                                    "
                                  >
                                    , {{ delivery?.billing_relation.zip_code }}
                                  </template>
                                </p>
                              </template>

                              <v-layout class="mt-3 align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading"
                                    ><template
                                      v-if="delivery?.type == 'transfer'"
                                    >
                                      From
                                    </template>
                                    Contact Details</label
                                  ></v-flex
                                >
                              </v-layout>
                              <template
                                v-if="
                                  delivery?.billing_contact_person_relation?.id
                                "
                              >
                                <p class="mb-0">
                                  {{
                                    delivery?.billing_contact_person_relation
                                      ?.display_name
                                  }}
                                </p>
                                <p
                                  class="mb-0"
                                  v-if="
                                    delivery?.billing_contact_person_relation
                                      ?.primary_phone
                                  "
                                >
                                  {{
                                    delivery?.billing_contact_person_relation
                                      ?.primary_phone
                                  }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no phone number</p>
                                </em>
                                <p
                                  class="mb-0"
                                  v-if="
                                    delivery?.billing_contact_person_relation
                                      ?.primary_email
                                  "
                                >
                                  {{
                                    delivery?.billing_contact_person_relation
                                      ?.primary_email
                                  }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no email</p>
                                </em>
                              </template>

                              <v-layout class="mt-3" v-if="false">
                                <v-flex md4
                                  ><label class="my-0"
                                    >Email Notification
                                  </label></v-flex
                                >
                                <v-flex md8>
                                  <v-switch
                                    v-model="delivery.billing_contact"
                                    class="m-0 p-0"
                                    color="cyan"
                                    dense
                                    inset
                                  />
                                </v-flex>
                              </v-layout>
                            </v-col>
                            <v-col
                              :md="delivery?.type == 'transfer' ? '3' : '4'"
                              class="bgfrom"
                              :class="
                                delivery?.type == 'transfer' ? 'roborder' : ''
                              "
                            >
                              <v-layout class="align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading"
                                    ><template
                                      v-if="delivery?.type == 'transfer'"
                                    >
                                      From
                                    </template>
                                    {{ getSiteAddressTitle() }}</label
                                  ></v-flex
                                >
                              </v-layout>

                              <template v-if="delivery?.property_relation?.id">
                                <span
                                  v-if="
                                    delivery?.property_relation &&
                                    delivery?.property_relation.property_address
                                  "
                                >
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p
                                  class="mr-2 text-capitalize"
                                  v-if="
                                    delivery?.property_relation &&
                                    delivery?.property_relation.property_address
                                  "
                                >
                                  <template
                                    v-if="
                                      delivery?.property_relation?.property_name
                                    "
                                  >
                                    {{
                                      delivery?.property_relation
                                        ?.property_name
                                    }}<br />
                                  </template>
                                  {{ delivery?.property_relation.street_1 }},
                                  <br
                                    v-if="
                                      delivery?.property_relation.street_2 ||
                                      delivery?.property_relation.unit_no
                                    "
                                  />
                                  <template
                                    v-if="delivery?.property_relation.street_2"
                                  >
                                    {{ delivery?.property_relation.street_2 }},
                                  </template>
                                  <template
                                    v-if="delivery?.property_relation.unit_no"
                                  >
                                    {{ delivery?.property_relation.unit_no }},
                                  </template>
                                  <br />
                                  {{ delivery?.property_relation.country }}
                                  <template
                                    v-if="
                                      delivery?.property_relation &&
                                      delivery?.property_relation.zip_code !=
                                        '000000'
                                    "
                                  >
                                    , {{ delivery?.property_relation.zip_code }}
                                  </template>
                                </p>
                              </template>

                              <v-layout class="mt-3 align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading"
                                    ><template
                                      v-if="delivery?.type == 'transfer'"
                                    >
                                      From
                                    </template>
                                    Contact Details</label
                                  ></v-flex
                                >
                              </v-layout>

                              <template
                                v-if="
                                  delivery?.property_contact_person_relation?.id
                                "
                              >
                                <p class="mb-0">
                                  {{
                                    delivery?.property_contact_person_relation
                                      ?.display_name
                                  }}
                                </p>
                                <p
                                  class="mb-0"
                                  v-if="
                                    delivery?.property_contact_person_relation
                                      ?.primary_phone
                                  "
                                >
                                  {{
                                    delivery?.property_contact_person_relation
                                      ?.primary_phone
                                  }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no phone number</p>
                                </em>
                                <p
                                  class="mb-0"
                                  v-if="
                                    delivery?.property_contact_person_relation
                                      ?.primary_email
                                  "
                                >
                                  {{
                                    delivery?.property_contact_person_relation
                                      ?.primary_email
                                  }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no email</p>
                                </em>
                              </template>

                              <v-layout class="mt-3" v-if="false">
                                <v-flex md4
                                  ><label class="my-0"
                                    >Email Notification
                                  </label></v-flex
                                >
                                <v-flex md8>
                                  <v-switch
                                    v-model="delivery.property_contact"
                                    class="m-0 p-0"
                                    color="cyan"
                                    dense
                                    inset
                                  />
                                </v-flex>
                              </v-layout>
                            </v-col>
                            <v-col
                              :md="delivery?.type == 'transfer' ? '3' : '4'"
                              class="bgfrom"
                              v-if="delivery?.type != 'transfer'"
                            >
                              <v-layout class="align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading"
                                    >{{ getPickupAddressTitle() }}
                                    <template v-if="delivery?.picAddress?.id">
                                      <v-chip
                                        label
                                        small
                                        class="ml-1"
                                        color="red"
                                        outlined
                                        style="
                                          min-height: 24px;
                                          font-size: 13px;
                                        "
                                      >
                                        A&G
                                      </v-chip></template
                                    >
                                  </label></v-flex
                                >
                              </v-layout>

                              <template v-if="delivery?.picAddress?.id">
                                <span v-if="delivery?.picAddress">
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p class="mr-2 text-capitalize">
                                  <template
                                    v-if="delivery?.picAddress?.property_name"
                                  >
                                    {{ delivery?.picAddress?.property_name
                                    }}<br />
                                  </template>
                                  {{ delivery?.picAddress.street_1 }},
                                  <br
                                    v-if="
                                      delivery?.picAddress.street_2 ||
                                      delivery?.picAddress.unit_no
                                    "
                                  />
                                  <template
                                    v-if="delivery?.picAddress.street_2"
                                  >
                                    {{ delivery?.picAddress.street_2 }},
                                  </template>
                                  <template v-if="delivery?.picAddress.unit_no">
                                    {{ delivery?.picAddress.unit_no }},
                                  </template>
                                  <br />
                                  {{ delivery?.picAddress.country }}
                                  <template
                                    v-if="
                                      delivery?.picAddress &&
                                      delivery?.picAddress.zip_code != '000000'
                                    "
                                  >
                                    , {{ delivery?.picAddress.zip_code }}
                                  </template>
                                </p>
                              </template>

                              <v-layout class="mt-3 align-center">
                                <v-flex md12
                                  ><label class="my-0 address-heading"
                                    >Contact Details</label
                                  ></v-flex
                                >
                              </v-layout>

                              <template v-if="delivery?.picAddressuser?.id">
                                <p class="mb-0">
                                  {{ delivery?.picAddressuser?.display_name }}
                                </p>
                                <p
                                  class="mb-0"
                                  v-if="delivery?.picAddressuser?.phone_number"
                                >
                                  {{ delivery?.picAddressuser?.phone_number }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no phone number</p>
                                </em>
                                <p
                                  class="mb-0"
                                  v-if="delivery?.picAddressuser?.user_email"
                                >
                                  {{ delivery?.picAddressuser?.user_email }}
                                </p>
                                <em v-else class="text-muted">
                                  <p class="mb-0">no email</p>
                                </em>
                              </template>

                              <v-layout class="mt-3" v-if="false">
                                <v-flex md4
                                  ><label class="my-0"
                                    >Email Notification
                                  </label></v-flex
                                >
                                <v-flex md8>
                                  <v-switch
                                    v-model="delivery.property_contact"
                                    class="m-0 p-0"
                                    color="cyan"
                                    dense
                                    inset
                                  />
                                </v-flex>
                              </v-layout>
                            </v-col>
                            <template v-if="delivery?.type == 'transfer'">
                              <v-col
                                :md="delivery?.type == 'transfer' ? '3' : '4'"
                                class="bgto"
                              >
                                <v-layout class="align-center">
                                  <v-flex md12
                                    ><label class="my-0 address-heading"
                                      ><template
                                        v-if="delivery?.type == 'transfer'"
                                      >
                                        To </template
                                      >Billing Location</label
                                    >
                                    <template
                                      v-if="
                                        delivery?.to_billing_relation
                                          ?.withcompany == 1
                                      "
                                    >
                                      <v-chip
                                        label
                                        small
                                        class="ml-1"
                                        color="red"
                                        outlined
                                        style="
                                          min-height: 24px;
                                          font-size: 13px;
                                        "
                                      >
                                        A&G
                                      </v-chip></template
                                    >
                                    <template
                                      v-if="
                                        delivery?.select_billing_type ==
                                          'to_customer_billing' &&
                                        delivery?.type == 'transfer'
                                      "
                                    >
                                      <v-chip
                                        label
                                        small
                                        class="ml-1 text-white"
                                        color="green"
                                        style="
                                          min-height: 24px;
                                          font-size: 13px;
                                        "
                                      >
                                        Billing
                                      </v-chip></template
                                    >
                                  </v-flex>
                                </v-layout>
                                <template
                                  v-if="delivery?.to_billing_relation?.id"
                                >
                                  <span
                                    v-if="
                                      delivery?.to_billing_relation &&
                                      delivery?.to_billing_relation
                                        .property_address
                                    "
                                  >
                                    <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                                  >
                                  <p
                                    class="text-capitalize"
                                    v-if="
                                      delivery?.to_billing_relation &&
                                      delivery?.to_billing_relation
                                        .property_address
                                    "
                                  >
                                    <template
                                      v-if="
                                        delivery?.to_billing_relation
                                          ?.property_name
                                      "
                                    >
                                      {{
                                        delivery?.to_billing_relation
                                          ?.property_name
                                      }}<br />
                                    </template>
                                    {{
                                      delivery?.to_billing_relation?.street_1
                                    }},
                                    <br
                                      v-if="
                                        delivery?.to_billing_relation
                                          ?.street_2 ||
                                        delivery?.to_billing_relation?.unit_no
                                      "
                                    />
                                    <template
                                      v-if="
                                        delivery?.to_billing_relation?.street_2
                                      "
                                    >
                                      {{
                                        delivery?.to_billing_relation.street_2
                                      }},
                                    </template>
                                    <template
                                      v-if="
                                        delivery?.to_billing_relation?.unit_no
                                      "
                                    >
                                      {{
                                        delivery?.to_billing_relation?.unit_no
                                      }},
                                    </template>
                                    <br />
                                    {{ delivery?.to_billing_relation?.country }}
                                    <template
                                      v-if="
                                        delivery?.to_billing_relation &&
                                        delivery?.to_billing_relation
                                          .zip_code != '000000'
                                      "
                                    >
                                      ,
                                      {{
                                        delivery?.to_billing_relation.zip_code
                                      }}
                                    </template>
                                  </p>
                                </template>

                                <v-layout class="mt-3 align-center">
                                  <v-flex md12
                                    ><label class="my-0 address-heading"
                                      ><template
                                        v-if="delivery?.type == 'transfer'"
                                      >
                                        To
                                      </template>
                                      Contact Details</label
                                    ></v-flex
                                  >
                                </v-layout>
                                <template
                                  v-if="
                                    delivery?.to_billing_contact_person_relation
                                      ?.id
                                  "
                                >
                                  <p class="mb-0">
                                    {{
                                      delivery
                                        ?.to_billing_contact_person_relation
                                        .display_name
                                    }}
                                  </p>
                                  <p class="mb-0">
                                    {{
                                      delivery
                                        ?.to_billing_contact_person_relation
                                        .primary_phone
                                    }}
                                  </p>
                                  <p class="mb-0">
                                    {{
                                      delivery
                                        ?.to_billing_contact_person_relation
                                        .primary_email
                                    }}
                                  </p>
                                </template>

                                <v-layout class="mt-3" v-if="false">
                                  <v-flex md4
                                    ><label class="my-0"
                                      >Email Notification
                                    </label></v-flex
                                  >
                                  <v-flex md8>
                                    <v-switch
                                      v-model="delivery.billing_contact"
                                      class="m-0 p-0"
                                      color="cyan"
                                      dense
                                      inset
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-col>
                              <v-col
                                :md="delivery?.type == 'transfer' ? '3' : '4'"
                                class="bgto"
                              >
                                <v-layout class="align-center">
                                  <v-flex md12
                                    ><label class="my-0 address-heading"
                                      ><template
                                        v-if="delivery?.type == 'transfer'"
                                      >
                                        To
                                      </template>
                                      Dropoff Address</label
                                    ></v-flex
                                  >
                                </v-layout>

                                <template
                                  v-if="delivery?.to_property_relation?.id"
                                >
                                  <span
                                    v-if="
                                      delivery?.to_property_relation &&
                                      delivery?.to_property_relation
                                        .property_address
                                    "
                                  >
                                    <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                  >
                                  <p
                                    class="mr-2 text-capitalize"
                                    v-if="
                                      delivery?.to_property_relation &&
                                      delivery?.to_property_relation
                                        .property_address
                                    "
                                  >
                                    <template
                                      v-if="
                                        delivery?.to_property_relation
                                          ?.property_name
                                      "
                                    >
                                      {{
                                        delivery?.to_property_relation
                                          ?.property_name
                                      }}<br />
                                    </template>
                                    {{
                                      delivery?.to_property_relation.street_1
                                    }},
                                    <br
                                      v-if="
                                        delivery?.to_property_relation
                                          .street_2 ||
                                        delivery?.to_property_relation.unit_no
                                      "
                                    />
                                    <template
                                      v-if="
                                        delivery?.to_property_relation.street_2
                                      "
                                    >
                                      {{
                                        delivery?.to_property_relation.street_2
                                      }},
                                    </template>
                                    <template
                                      v-if="
                                        delivery?.to_property_relation.unit_no
                                      "
                                    >
                                      {{
                                        delivery?.to_property_relation.unit_no
                                      }},
                                    </template>
                                    <br />
                                    {{ delivery?.to_property_relation.country }}
                                    <template
                                      v-if="
                                        delivery?.to_property_relation &&
                                        delivery?.to_property_relation
                                          .zip_code != '000000'
                                      "
                                    >
                                      ,
                                      {{
                                        delivery?.to_property_relation.zip_code
                                      }}
                                    </template>
                                  </p>
                                </template>

                                <v-layout class="mt-3 align-center">
                                  <v-flex md12
                                    ><label class="my-0 address-heading"
                                      ><template
                                        v-if="delivery?.type == 'transfer'"
                                      >
                                        To
                                      </template>
                                      Contact Details</label
                                    ></v-flex
                                  >
                                </v-layout>

                                <template
                                  v-if="
                                    delivery
                                      ?.to_property_contact_person_relation?.id
                                  "
                                >
                                  <p class="mb-0">
                                    {{
                                      delivery
                                        ?.to_property_contact_person_relation
                                        .display_name
                                    }}
                                  </p>

                                  <p
                                    class="mb-0"
                                    v-if="
                                      delivery
                                        ?.to_property_contact_person_relation
                                        .primary_phone
                                    "
                                  >
                                    {{
                                      delivery
                                        ?.to_property_contact_person_relation
                                        .primary_phone
                                    }}
                                  </p>
                                  <em v-else class="text-muted">
                                    <p class="mb-0">no phone number</p>
                                  </em>
                                  <p
                                    class="mb-0"
                                    v-if="
                                      delivery
                                        ?.to_property_contact_person_relation
                                        .primary_email
                                    "
                                  >
                                    {{
                                      delivery
                                        ?.to_property_contact_person_relation
                                        .primary_email
                                    }}
                                  </p>
                                  <em v-else class="text-muted">
                                    <p class="mb-0">no email</p>
                                  </em>
                                </template>

                                <v-layout class="mt-3" v-if="false">
                                  <v-flex md4
                                    ><label class="my-0"
                                      >Email Notification
                                    </label></v-flex
                                  >
                                  <v-flex md8>
                                    <v-switch
                                      v-model="delivery.property_contact"
                                      class="m-0 p-0"
                                      color="cyan"
                                      dense
                                      inset
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-col>
                            </template>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                    v-if="delivery?.force_close_remark"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Force Closed Remark</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          delivery.force_close_remark ||
                          '<em>No Force Closed Remark</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Description</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          delivery.description || '<em>No Description</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>

                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >TERMS &amp; CONDITIONS</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <span class="font-weight-600 ml-4 mb-1 text-capitalize">
                        {{ delivery?.term_condition_label?.label }}
                      </span>
                      <read-more
                        class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color text-capitalize"
                        more-str="read more"
                        :text="
                          delivery.term_conditions ||
                          '<em>No Terms &amp; Conditions</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4"
                  >
                    <v-card-title class="headline grey lighten-4">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                        >Notes</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-layout class="mb-4">
                        <v-flex md6 class="custom-border-right">
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Client Note
                            </span>
                          </v-card-title>
                          <read-more
                            class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              delivery.client_remark ||
                              '<em>no Client Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                        <v-flex md6>
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Admin Note</span
                            >
                          </v-card-title>

                          <read-more
                            class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              delivery.admin_remark || '<em>No Admin Note </em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        v-if="delivery?.documents?.length && false"
                        class="custom-border-top"
                      >
                        <v-flex md12>
                          <FileTemplate
                            :attachments="delivery.documents"
                          ></FileTemplate>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item value="attachment">
                <AdditionalDocuments
                  :type-id="delivery.id"
                  class="mx-4"
                  type-text="delivery"
                  :type="999"
                  :extensive="false"
                  :add-doc="documents"
                  v-on:forceclose="getDelivery()"
                ></AdditionalDocuments>
              </v-tab-item>

              <v-tab-item
                v-if="getPermission('history:view')"
                :value="'history'"
              >
                <InternalHistoryDetail
                  type="delivery"
                  :type_id="delivery.id"
                ></InternalHistoryDetail>
              </v-tab-item>
              <v-tab-item value="team">
                <DeliveryTeamDetail
                  :delivery-id="deliveryId"
                  v-on:delete:engineer="getDelivery()"
                >
                </DeliveryTeamDetail>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->

      <Dialog :common-dialog="appointmentUpdateDialog" v-if="false">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 27vh">
            <v-row>
              <!-- <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
                <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
                <v-col md="4" class="py-1 font-weight-600"> End Time </v-col> -->
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Date</div>
                <Datepicker
                  :defaultDate="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                >
                </Datepicker>
              </v-col>
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Time</div>
                <TimePicker
                  key="visit-schedule-start-time"
                  id="schedule-start-time"
                  placeholder="Start Time"
                  v-model="starTimereschdule"
                  :min-time="todayTime"
                  v-on:change="addTime(starTimereschdule)"
                />
                <!--   <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    id="start-time"
                    :items="timeList"
                    v-model.trim="starTimereschdule"
                    placeholder="Start Time"
                    label="Start Time"
                    solo
                    flat
                    item-color="cyan"
                    hide-details
                  /> -->
              </v-col>
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Date</div>
                <Datepicker
                  :defaultDate="updateEndDate"
                  solo
                  v-on:update:date-picker="updateAppointmentEndDate"
                >
                </Datepicker>
              </v-col>
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Time</div>
                <TimePicker
                  key="visit-schedule-end-time"
                  id="schedule-end-time"
                  placeholder="End Time"
                  v-model="endTimereschdule"
                  :min-time="updatedDate"
                />
                <!--     <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    id="end-time"
                    :items="timeList"
                    v-model.trim="endTimereschdule"
                    placeholder="End Time"
                    label="End Time"
                    solo
                    flat
                    item-color="cyan"
                    hide-details
                  /> -->
              </v-col>

              <v-col md="12" class="py-2">
                <em
                  >All engineers will be removed from delivery#
                  {{ delivery.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <DeleteDialog :delete-dialog="deleteVisitDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ delivery.barcode }}</span
            >, you won't be able to retrieve this later.<br />Are you sure you
            want to delete
            <span class="font-weight-700">{{ delivery.barcode }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteVisitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteVisitDialog = false"
            >No! Cancel</v-btn
          >
          <v-btn
            :disabled="deleteVisitLoading"
            :loading="deleteVisitLoading"
            color="red lighten-1 white--text"
            v-on:click="deleteVisitConfirm()"
            class="mx-2 custom-grey-border custom-bold-button"
            >Yes! Delete</v-btn
          >
        </template>
      </DeleteDialog>

      <Dialog :common-dialog="confirm_dialog_force" v-if="confirm_dialog_force">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form
            ref="createForceClosedReff"
            v-model.trim="formValid"
            lazy-validation
          >
            <v-container class="px-5" fluid>
              <!--  <p style="color: black" class="ml-1">
                If you force close the visit,
                <span class="font-weight-600 red--text text--darken-3"
                  >all draft service forms and team</span
                >
                will be permanently deleted.
              </p> -->
              <p
                class="font-weight-600 font-size-19 ml-1"
                :class="status_type == 'cancel' ? 'text-center' : ''"
                style="margin-bottom: 0px !important"
              >
                Are you sure, you want to
                <span class="font-weight-700">
                  <template v-if="status_type == 'forceclosed'">
                    Force Closed
                  </template>
                  <template v-if="status_type == 'cancel'"> Cancel </template>
                </span>
                delivery order {{ delivery?.barcode }}?
              </p>
              <v-row class="py-0" v-if="status_type == 'forceclosed'">
                <v-col md="12">
                  <label
                    class="py-1 mb-1 font-weight-600 required"
                    style="margin-left: 5px !important"
                    >Remark</label
                  >
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model.trim="forceClosed.force_close_remark"
                    :rules="[
                      validateRules.required(
                        forceClosed.force_close_remark,
                        'Remark'
                      ),
                    ]"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="25"
                    counter="250"
                  />
                </v-col>
                <v-col md="12">
                  <label class="py-1 ml-2 font-weight-600">Attachments</label>
                  <FileUpload v-model="forceClosed.files" :maxLimit="5">
                  </FileUpload>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <template>
            <v-btn
              v-on:click="createForceClosed"
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Yes! Continue
            </v-btn>
          </template>
          <v-btn
            :disabled="pageLoading"
            v-on:click="confirm_dialog_force = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template-New.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";

/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */
/* import TextAreaField from "@/view/components/app-component/TextAreaField.vue"; */
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeliveryTeamDetail from "@/view/pages/delivery-job/partials/Delivery-Team-Detail.vue";

import VisitLineItem from "@/view/components/VisitLineItem.vue";
import DeliveriesTab from "@/view/pages/partials/Deliveries-Tab";

import Datepicker from "@/view/pages/partials/Datepicker.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import { mapGetters } from "vuex";

import Dialog from "@/view/pages/partials/Dialog";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import FileUpload from "@/view/components/app-component/FileInput.vue";

import {
  GET,
  /*   QUERY,
  PATCH,
  POST, */
  POST,
  PUT,
  DELETE,
} from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";

import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { toSafeInteger } from "lodash";
import { currentUser } from "@/core/services/jwt.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ObjectPath from "object-path";

import {
  SET_CUSTOMER,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
} from "@/core/services/store/visit.module";
import { GET_AVAILABLE_ENGINEER } from "@/core/services/store/delivery.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      documents: [],
      deliveryId: 0,
      delivery: {},
      yourFormat: "hh:mm A",
      deleteVisitDialog: false,
      pageLoading: false,
      pageLoaded: false,
      statusLoading: false,
      confirm_loading: false,
      visitLoading: false,
      appointmentUpdateDialog: false,
      deleteVisitLoading: false,
      engineerList: [],
      moreActions: [],
      confirm_dialog_force: false,
      status_type: null,
      apptEngLoading: false,
      forceClosed: {
        force_close_remark: null,
        files: [
          {
            file: null,
            name: null,
          },
        ],
        action: null,
      },
    };
  },
  methods: {
    detailDelivery(data) {
      let routeName = null;
      if (data?.type == "incoming") {
        routeName = "incoming-delivery.detail";
      } else if (data?.type == "outgoing") {
        routeName = "outgoing-delivery.detail";
      } else if (data?.type == "transfer") {
        routeName = "transfer-delivery.detail";
      }
      this.$router.push(
        this.getDefaultRoute(routeName, {
          params: {
            id: data?.id,
          },
        })
      );
    },
    pushToDelivery(type) {
      let routerName = "";
      let jobType = "";
      let duplicateConvertType = "";
      if (type == "convert_to_outgoing") {
        routerName = "outgoing-delivery.create";
        jobType = "outgoing";
        duplicateConvertType = "rn-do";
      }
      if (type == "convert_to_incoming") {
        routerName = "incoming-delivery.create";
        jobType = "incoming";
        duplicateConvertType = "do-rn";
      }
      this.$router.push(
        this.getDefaultRoute(routerName, {
          query: {
            type: jobType,
            duplicateType: duplicateConvertType,
            duplicateId: this.deliveryId,
          },
        })
      );
    },
    pushToUpdate() {
      let route = "";
      if (this.delivery?.type == "incoming") {
        route = "admin.incoming-delivery.update";
      } else if (this.delivery?.type == "outgoing") {
        route = "admin.outgoing-delivery.update";
      } else if (this.delivery?.type == "transfer") {
        route = "admin.transfer-delivery.update";
      }
      let id = this.deliveryId;
      this.$router.push({
        name: route,
        params: { id },
      });
    },
    createForceClosed() {
      const _this = this;

      if (!_this.$refs.createForceClosedReff.validate()) {
        return false;
      }
      _this.pageLoading = true;

      this.forceClosed.action = this.status_type;

      _this.$store
        .dispatch(POST, {
          url: "delivery-force-close/" + this.deliveryId,
          data: this.forceClosed,
        })
        .then(() => {
          (_this.confirm_dialog_force = false), _this.getDelivery();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateMoreAction(type) {
      switch (type) {
        case "re_schedule_visit":
          this.appointmentUpdateDialog = true;
          break;
        case "mark_as_forceclosed":
          this.status_type = "forceclosed";
          this.confirm_dialog_force = true;
          //this.updateStatus("mark_as_cancel");
          break;
        case "mark_as_cancel":
          this.status_type = "cancel";
          this.confirm_dialog_force = true;
          break;
        case "mark_as_delete":
          this.deleteVisitDialog = true;
          break;
        case "convert_to_visit":
          this.pushToCreateVisit();
          break;
        case "available_staff":
          this.getAvailableEngineers();
          break;
      }
    },
    pushToCreateVisit() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            convertType: "delivery",
            convertId: this.deliveryId,
            is_repair: this.delivery?.is_repair,
            is_exchange: this.delivery?.is_exchange,
          },
        })
      );
    },
    toRoute() {
      let route =
        this.delivery?.customer_type == "customer-list"
          ? "admin.customer.detail"
          : "admin.supplier.detail";
      let id = this.delivery.customer;
      this.$router.push({
        name: route,
        params: { id },
      });
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    getIcon() {
      if (this.delivery?.type == "incoming") {
        return "mdi-transfer-down";
      } else if (this.delivery?.type == "outgoing") {
        return "mdi-transfer-up";
      } else if (this.delivery?.type == "transfer") {
        return "mdi-transfer";
      }
    },

    getColor() {
      if (this.delivery?.type == "incoming") {
        return "incoming-color white--text";
      } else if (this.delivery?.type == "outgoing") {
        return "outgoing-color white--text";
      } else if (this.delivery?.type == "transfer") {
        return "transfer-color";
      }
    },

    getSiteAddressTitle() {
      if (this.delivery?.type == "incoming") {
        return "Pickup Address";
      } else if (this.delivery?.type == "outgoing") {
        return "Dropoff Address";
      } else if (this.delivery?.type == "transfer") {
        return "Pickup Address";
      }
    },
    getPickupAddressTitle() {
      if (this.delivery?.type == "incoming") {
        return "Dropoff Address";
      } else if (this.delivery?.type == "outgoing") {
        return "Pickup Address";
      } else if (this.delivery?.type == "transfer") {
        return "Dropoff Address";
      }
    },
    deleteVisitConfirm() {
      this.deleteVisitLoading = true;

      let route = "";
      if (this.delivery?.type == "incoming") {
        route = "incoming-delivery";
      } else if (this.delivery?.type == "outgoing") {
        route = "outgoing-delivery";
      } else if (this.delivery?.type == "transfer") {
        route = "transfer-delivery";
      }

      this.$store
        .dispatch(DELETE, { url: `delivery/${this.deliveryId}` })
        .then(() => {
          this.deleteVisitDialog = false;
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute(route, {
                query: {
                  tab: "all",
                },
              })
            );
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },

    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },

    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentEndDate(param) {
      this.updateAppointmentFormData.end_date = param;
    },

    getAvailableEngineers() {
      this.$store
        .dispatch(GET_AVAILABLE_ENGINEER, {
          force: /*  this.deliveryId && this.deliveryId > 0 ? 0 : */ 1,
        })
        .then((output) => {
          if (output) {
            this.engineerList = this.availableDeliveryTeam;
            this.engineer_menu = true;
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },

    updateEngineer() {
      if (!this.assigned_team_new.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      }
      this.apptEngLoading = true;
      this.$store
        .dispatch(PUT, {
          url: "/delivery/" + this.deliveryId + "/driver",
          data: {
            engineer: this.assigned_team_new,
          },
        })
        .then(() => {
          this.engineerList = [];
          this.$nextTick(() => {
            this.getDelivery();
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptEngLoading = false;
        });
    },
    getDelivery() {
      this.$store
        .dispatch(GET, { url: "delivery/v1/" + this.deliveryId })
        .then(({ data }) => {
          this.starTimereschdule = moment(data.started_at).format("hh:mm A");
          this.endTimereschdule = moment(data.finished_at).format("hh:mm A");

          this.delivery = data;

          this.moreActions = this.delivery.more_actions;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Delivery", route: "delivery" },
            { title: "Detail" },
            { title: this.delivery.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },

    routeToUpdate() {
      this.$router.push(
        this.getDefaultRoute("job.update", {
          query: {
            do_update: 1,
            /*     job_id: ObjectPath.get(this.detail, "id", null),
            job_type:
              ObjectPath.get(this.detail, "type", 1) == 1
                ? "one-off"
                : "recurring", */
          },
        })
      );

      this.$store.commit(
        SET_CUSTOMER,
        ObjectPath.get(this.delivery, "customer", null)
      );
      this.$store.commit(
        SET_PROPERTY,
        ObjectPath.get(this.delivery, "property", null)
      );
      this.$store.commit(
        SET_PROPERTY_CONTACT,
        ObjectPath.get(this.delivery, "property_person", null)
      );
      this.$store.commit(
        SET_BILLING,
        ObjectPath.get(this.delivery, "billing", null)
      );
      this.$store.commit(
        SET_BILLING_CONTACT,
        ObjectPath.get(this.delivery, "billing_person", null)
      );
    },
  },
  created() {
    this.deliveryId =
      this.$route && this.$route.params && this.$route.params.id
        ? toSafeInteger(this.$route.params.id)
        : 0;
  },
  mounted() {
    this.getDelivery();
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters(["vAvailableTeam"]),
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    is_staff_login() {
      const user = currentUser();
      const role_engineer = this.lodash.toLower(user.engineer);

      return role_engineer == role_engineer;
    },

    deliveryTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.deliveryTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
  },
  components: {
    Dialog,
    Priority,
    VisitLineItem,
    DeliveriesTab,
    FileTemplate,
    DetailTemplate,
    Datepicker,
    Barcode,
    DeleteDialog,
    TimePicker,
    DeliveryTeamDetail,
    InternalHistoryDetail,
    AdditionalDocuments,
    TextAreaField,
    FileUpload,
    VisitListingTemplate,
  },
};
</script>
