<template>
  <div class="pr-2">
    <template
      v-if="db_line_items && db_line_items.length && db_line_items.length > 0"
    >
      <div class="my-3">
        <div class="d-flex align-center justify-end">
          <!--  <v-checkbox
            v-if="!isEnquiry && !isInvoice && !isQuotation && !isPreventive"
            readonly
            class="my-0 py-0"
            v-model="showPrice"
            color="cyan white--text"
            label="Show Price To Engineer"
          /> -->
          <!-- <div class="px-3">
          <span class="ml-2">Show Price To Engineer : &nbsp;</span>
          <template v-if="showPrice">
            <v-chip color="green" small outlined label>
              <span class="">YES</span>
            </v-chip>
          </template>
          <template v-else>
            <v-chip color="red" small outlined label>
              <span class=""> NO</span>
            </v-chip>
          </template>
        </div> -->
          <div
            class="d-flex align-center ml-4 pl-4 custom-border-left"
            v-if="false"
          >
            <div class="mr-2">
              <label for="discount-level" class="mb-0 font-weight-600"
                >Discount Level</label
              >
            </div>
            <div>
              <v-radio-group
                readonly
                id="discount-level"
                v-model="discountLevel"
                row
                hide-details
                class="px-0 py-0"
              >
                <v-radio
                  label="Line Item"
                  value="line_item"
                  color="cyan"
                ></v-radio>
                <v-radio
                  label="Transaction"
                  value="transaction"
                  color="cyan"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex grey lighten-4 px-2">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 5%"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          :style="get_col_style('product')"
        >
          <template v-if="relatedType == 10"
            >Spare Parts<template v-if="showEqup()">
              / Equipment</template
            ></template
          >
          <template v-else>Spare Parts/Service/ Equipment</template>

          <!--  <template v-if="relatedType == 7"> Equipment</template>
          <template v-else> Spare Part/Service/ Equipment</template> -->
        </div>
        <div
          v-if="relatedData?.type != 'incoming'"
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 10%"
        >
          Selling/Unit Cost
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 10%"
        >
          Quantity
        </div>
        <div
          v-if="discountLevel == 'line_item' && relatedData?.type != 'incoming'"
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 15%"
        >
          Discount
        </div>
        <div
          v-if="relatedData?.type != 'incoming'"
          class="color-custom-blue py-2 font-weight-700 text-right"
          style="width: 10%"
        >
          Total
        </div>
      </div>
      <div
        v-for="(row, index) in db_line_items"
        class="line-item-drag"
        :key="`line-item-drag-${index}`"
        :class="`${row.class} ${row.type == 'header' ? '' : ''}`"
      >
        <div
          :id="`line-item-drag-${index}`"
          class="d-flex py-2 px-2"
          :class="{ 'item-is-child': row.is_child }"
        >
          <div
            class="font-weight-600 text-center"
            style="width: 5%"
            v-if="row.product_type != 'header'"
          >
            <template>
              <div
                class="font-weight-700 mt-2"
                style="font-size: 15px"
                v-if="!row.is_child"
              >
                {{ row.sno }}.
              </div>
            </template>
          </div>
          <div
            class="font-weight-600 text-center"
            style="width: 5%"
            v-if="row.is_child"
          >
            <template>
              <div class="font-weight-700 mt-2" style="font-size: 15px">
                {{ row.sno }}.
              </div>
            </template>
          </div>
          <div
            v-if="row.type == 'header'"
            class="text-left"
            style="width: 100%"
          >
            <div class="d-flex">
              <div
                class="font-weight-700"
                style="font-size: 15px; margin-left: 24px"
              >
                {{ row.product }}
              </div>

              <div class="text-center" style="width: 50%"></div>
              <!-- <v-chip
              color="cyan white--text"
              label
              class="lip-type text-uppercase font-weight-600 header"
            >
              Header
            </v-chip> -->
            </div>
          </div>
          <div
            v-else
            class="text-left"
            :style="get_col_style('product', row.is_child)"
          >
            <div class="d-flex">
              <v-avatar size="40" v-if="row.product_type != 'other'">
                <img
                  v-if="row?.image?.url"
                  :src="row?.image?.url"
                  :alt="row?.image?.name"
                />
                <img v-else :src="$defaultImage" />
              </v-avatar>
              <div style="width: 100%" class="ml-4">
                <v-expansion-panels class="cepfli">
                  <v-expansion-panel class="elevation-0">
                    <v-expansion-panel-header>
                      <div class="d-flex">
                        <span class="font-weight-700 font-size-18 pl-2">
                          {{ row.product }}
                        </span>
                        <v-spacer></v-spacer>
                        <!--    <div>
                            <v-tooltip
                              top
                              content-class="custom-top-tooltip"
                              v-if="row && row.trip && row.trip.barcode"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  label
                                  color="chip-custom-blue"
                                  outlined
                                  small
                                >
                                  {{ row.trip.barcode }}
                                </v-chip>
                              </template>
                              <span class="text-uppercase"> Visit Trip Id </span>
                            </v-tooltip>
                          </div> -->

                        <v-chip
                          v-if="row?.old_equp_item == 1 && relatedType == 10"
                          color="red white--text"
                          label
                          class="lip-type text-uppercase font-weight-600"
                          style="margin-top: 2px"
                        >
                          Old Equipment
                        </v-chip>
                        <div style="width: 50px; text-align: end">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                color="cyan white--text"
                                label
                                class="lip-type text-uppercase font-weight-600"
                                :class="{
                                  product: row.product_type == 'goods',
                                  service: row.product_type == 'service',
                                  equipment: row.product_type == 'equipment',
                                  tools: row.product_type == 'tools',
                                  other: row.product_type == 'other',
                                }"
                              >
                                <template v-if="row.type == 'tools'">
                                  ET</template
                                >
                                <template v-else-if="row.type == 'product'">
                                  S
                                </template>
                                <template v-else>
                                  {{ row.type.charAt(0) }}</template
                                >
                              </v-chip>
                            </template>
                            <span class="text-uppercase">
                              <template v-if="row.type == 'tools'">
                                Equipment/Tools</template
                              >
                              <template v-else> {{ row.type }}</template>
                            </span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="ml-n6 pl-2">
                        <template v-if="row.description">{{
                          row.description
                        }}</template>
                        <em v-else class="text-muted"> no description</em>
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div
                  v-if="row && row.item_images && row.item_images.length"
                  class="d-flex"
                >
                  <div
                    style="position: relative"
                    v-for="(nimg, nmlindex) in row.item_images"
                    :key="`new-image-${nmlindex}`"
                  >
                    <v-img
                      :aspect-ratio="1"
                      height="100px"
                      width="100px"
                      class="mr-4 my-2"
                      :src="nimg"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey darken-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </div>
                <v-checkbox
                  v-if="row.type == 'product' && row.is_equipment == 1"
                  inset
                  color="cyan"
                  label="As Equipment"
                  dense
                  disabled
                  class="pt-0 mt-2"
                  v-model="row.is_equipment"
                  hide-details
                  :false-value="0"
                  :true-value="1"
                >
                </v-checkbox>
                <div class="font-weight-600 d-flex align-center mt-2">
                  <template>
                    <v-chip
                      v-if="row.barcode"
                      label
                      color="chip-custom-blue"
                      outlined
                      small
                      class="text-white p-3 mt-2 mb-1"
                      style="font-weight: 700"
                      v-on:click="routeToDetail(row)"
                      >{{ row.barcode }}</v-chip
                    >

                    <template
                      v-if="
                        row.product_type == 'equipment' &&
                        row &&
                        row?.equipment_location
                      "
                    >
                      <v-chip
                        color="lightgray"
                        class="ml-2 p-3 mt-2"
                        small
                        text-color=""
                        outlined
                      >
                        <b class="font-weight-700">Location:</b>&nbsp;{{
                          row?.equipment_location
                        }}
                      </v-chip>
                    </template>

                    <template
                      v-if="
                        row.product_type == 'equipment' && row && row?.serial_no
                      "
                    >
                      <v-chip
                        color="lightgray"
                        class="ml-2 p-3 mt-2"
                        small
                        text-color=""
                        outlined
                      >
                        <b class="font-weight-700">Serials No.:</b>&nbsp;{{
                          row?.serial_no
                        }}
                      </v-chip>
                    </template>
                    <template
                      v-if="
                        row.product_type == 'equipment' && row && row?.sold_id
                      "
                    >
                      <v-chip
                        color="red"
                        class="ml-2 p-3 mt-2"
                        small
                        text-color=""
                        outlined
                        v-on:click.prevent.stop="
                          routeToDetailLink(
                            row?.sell_line_item,
                            'sold-items.detail'
                          )
                        "
                      >
                        SOLD - {{ row?.sell_barcode }}
                      </v-chip>
                    </template>
                    <template
                      v-if="
                        row.product_type == 'equipment' && row && row?.rental_id
                      "
                    >
                      <v-chip
                        color="red"
                        class="ml-2 p-3 mt-2"
                        small
                        text-color=""
                        outlined
                        v-on:click.prevent.stop="
                          routeToDetailLink(
                            row?.rental_line_item,
                            'rental-items.detail'
                          )
                        "
                      >
                        RENTED - {{ row?.rental_barcode }}
                      </v-chip>
                    </template>
                    <!--    <template
                        v-if="
                          row.product_type == 'equipment' &&
                          row &&
                          row.has_warranty &&
                          row.has_warranty == 1 &&
                          row.warranty_status > 0
                        "
                      >
                        <v-chip
                          :color="getWarrantyStatusColor(row.warranty_status)"
                          small
                          text-color="white"
                          class="text-white p-3 mt-2 mb-1 ml-2"
                        >
                          Warranty
                        </v-chip>
                        
                      </template> -->
                    <template
                      v-if="
                        row.product_type == 'equipment' &&
                        row &&
                        row.preventive_id &&
                        row.preventive_barcode &&
                        row.preventive_id > 0 &&
                        relatedType == 3
                      "
                    >
                      <v-chip
                        :color="row?.is_consumed ? 'red' : 'lightgray'"
                        class="p-3 mt-2 mb-1 ml-2"
                        small
                        text-color=""
                        outlined
                        v-on:click.prevent.stop="
                          routeToDetailLink(
                            row.preventive_id,
                            'preventive-maintanance.detail'
                          )
                        "
                      >
                        {{ row.preventive_barcode }}
                      </v-chip>
                    </template>
                    <!--  <v-chip
                      label
                      color="chip-custom-blue"
                      outlined
                      small
                      class="text-white p-3 mr-2"
                      v-on:click="routeToDetail(row)"
                      style="font-weight: 700; margin-top: -6px"
                      >{{ row.barcode }}</v-chip
                    > -->
                  </template>
                  <!-- <template v-if="row?.property?.parent?.name">
                    <v-chip small label>{{ row.property.parent.name }}</v-chip>
                    <v-icon class="px-1">mdi-chevron-right</v-icon>
                  </template>
                  <template v-if="row?.property?.child?.name">
                    <v-chip small label>{{ row.property.child.name }}</v-chip>
                    <v-icon class="px-1">mdi-chevron-right</v-icon>
                  </template>
                  <template v-if="row?.property?.type == 'child-child-'">
                    <v-chip small label style="padding: 10px !important">
                      <div>
                        <div>
                          {{ row?.property?.property_name }}
                        </div>
                        <div>{{ row.property.name }}</div>
                      </div>
                    </v-chip>
                  </template>
                  <template v-if="row?.property?.type == 'child-'">
                    <v-chip small label style="padding: 10px !important">
                      <div>
                        <div>
                          {{ row?.property?.property_name }}
                        </div>
                        <div>{{ row.property.name }}</div>
                      </div>
                    </v-chip>
                  </template>
                  <template v-if="row?.property?.type == 'parent-'">
                    <v-chip small style="padding: 10px !important">
                      <div>
                        <div>
                          {{ row?.property?.property_name }}
                        </div>
                        <div>{{ row.property.name }}</div>
                      </div>
                    </v-chip>
                  </template> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-center"
            style="width: 10%"
            v-if="!(row.type == 'header') && relatedData?.type != 'incoming'"
          >
            <div class="text-center">{{ row.rate }}</div>

            <v-tooltip
              top
              content-class="custom-top-tooltip"
              v-if="row.error_code_text_small"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 d-inline-table font-weight-600"
                  color="chip-custom-blue"
                  label
                  outlined
                >
                {{row.error_code_text_small}}
                </v-chip> -->
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                  small
                  label
                  style="padding: 10px !important"
                >
                  {{ row.error_code_text_small }}
                </v-chip>
              </template>
              <span>{{ row.error_code_text }}</span>
            </v-tooltip>
          </div>
          <div
            class="text-center"
            style="width: 10%"
            v-if="!(row.type == 'header')"
          >
            <div class="text-center">
              {{ row.quantity }} <span>{{ row.uom }}</span>
            </div>
          </div>
          <div
            class="text-center"
            style="width: 15%"
            v-if="discountLevel == 'line_item' && !(row.type == 'header')"
          >
            <div class="d-flex">
              <v-text-field
                readonly
                hide-details
                class="line-item-header-text px-0"
                placeholder="Discount"
                dense
                filled
                solo
                flat
                type="number"
                v-model="row.discount_value"
                color="cyan"
                style="width: 50%; min-width: unset"
              />
              <v-select
                readonly
                :items="discount_type_list"
                v-model="row.discount_type"
                hide-details
                item-text="type"
                item-value="value"
                item-color="cyan"
                class="line-item-header-text discount-type text-h6 px-0"
                placeholder="Type"
                dense
                filled
                solo
                flat
                color="cyan"
                style="width: 50%"
              />
            </div>
          </div>
          <div
            class="text-center"
            style="width: 10%"
            v-if="!(row.type == 'header') && relatedData?.type != 'incoming'"
          >
            <div class="text-right">{{ row.total }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center mt-2">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no line items at the moment.
      </div>
    </template>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
export default {
  name: "visit-line-item",
  props: {
    relatedData: {
      type: Object,
      default: null,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    discountLevel: {
      type: String,
      default: "transaction",
    },
    relatedId: {
      type: Number,
      default: 0,
    },
    isEnquiry: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPreventive: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    relatedType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      db_line_items: [],
      discount_type_list: [
        { type: "%", value: "2" },
        { type: "$", value: "1" },
      ],
      sno: 1,
      loading: false,
    };
  },
  methods: {
    showEqup() {
      if (this.relatedType == 10) {
        if (
          this.relatedData?.type == "incoming" &&
          this.relatedData?.customer_type == "supplier-list"
        ) {
          return false;
        }
        if (this.deliveryType == "outgoing") {
          return true;
        }
        if (
          this.relatedData?.type == "transfer" &&
          this.relatedData?.customer_type == "supplier-list"
        ) {
          return false;
        }
        return true;
      }
      return true;
    },
    routeToDetailLink(id, type) {
      this.$router.replace(
        this.getDefaultRoute(type, {
          params: { id },
        })
      );
    },
    routeToDetail(data) {
      let routeName = null;
      if (data.product_type == "equipment") {
        routeName = "equipment.detail";
      } else if (data.product_type == "goods") {
        routeName = "product.detail";
      } else if (data.product_type == "service") {
        routeName = "service.detail";
      }
      if (data && data.product_id && data.product_id > 0) {
        this.$router.replace(
          this.getDefaultRoute(routeName, {
            params: { id: data.product_id },
          })
        );
      }
    },
    get_line_items() {
      this.loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `detail-iteam/${this.relatedId}/line-items`,
          data: {
            type: this.relatedType,
          },
        })
        .then(({ data }) => {
          this.db_line_items = data;
          this.$emit("line-item", this.db_line_items.length);
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    get_col_style(param, child) {
      let width = 55;
      if (param == "product") {
        if (!this.showPrice) {
          width = this.relatedData?.type == "incoming" ? 90 : 80;
        } else if (this.showPrice) {
          width = 55;
          if (this.discountLevel == "transaction") {
            width = this.relatedData?.type == "incoming" ? 90 : 80;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    /*  get_col_style(param, child) {
      let width = 46;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 46;
          if (this.discount_level == "transaction") {
            width = 70;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    }, */
  },
  mounted() {
    this.get_line_items();
  },
  computed: {
    getSN: () => {
      return function (row, index) {
        if (index == 0) {
          this.sno = 1;
          console.log();
        }
        if (row.product_type == "header") {
          this.sno = 1;
        } else {
          this.sno = this.sno + 1;
        }
      };
    },
  },
};
</script>
