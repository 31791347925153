<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <template v-if="type == 'engineer' && selectedRows.length > 0">
      <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
        <h1 class="form-title selected-rows-text">
          {{ selectedRows.length }} Selected
        </h1>
        <v-spacer></v-spacer>
        <template v-if="getPermission('delivery:update')">
          <v-btn
            :disabled="dataLoading || hasDuplicateOrders"
            class="mx-2 custom-grey-border custom-bold-button mb-2"
            color="cyan white--text"
            v-on:click="confirm_dialog = true"
          >
            Update Order
          </v-btn>
        </template>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button mb-2"
          color="red lighten-1 white--text"
          v-on:click="clearSelections"
        >
          <v-icon dark left>mdi-close</v-icon>Clear Selections
        </v-btn>
      </v-flex>
    </template>
    <template v-else>
      <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
        <v-spacer></v-spacer>
        <v-text-field
          style="max-width: 250px !important"
          v-model.trim="search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          placeholder="Search"
          hide-details
          solo
          :disabled="dataLoading"
          :loading="dataLoading"
          color="cyan"
          @keydown.enter="get_all_deliveries"
          @keydown.tab="get_all_deliveries"
        ></v-text-field>
        <v-select
          style="max-width: 250px !important"
          :disabled="dataLoading"
          :loading="dataLoading"
          :items="statusList"
          v-model="status"
          hide-details
          flat
          solo
          dense
          placeholder="Status"
          color="cyan"
          item-color="cyan"
          class="pt-0 mt-0"
          item-text="text"
          item-value="status"
          clearable
          v-on:change="get_all_deliveries"
          v-on:click:clear="clearFilter('status')"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar size="20">
                <v-chip :color="item.color" text-color=""> </v-chip>
              </v-avatar>
            </v-list-item-action>
          </template>
        </v-select>
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="max-width: 338px !important"
              dense
              filled
              flat
              class="mb-2"
              placeholder="Delivery Date Range"
              hide-details
              solo
              :loading="dataLoading"
              :disabled="dataLoading"
              clearable
              prepend-inner-icon="mdi-calendar"
              v-on:click:clear="clearFilter('dates')"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="dataLoading"
            v-on:change="get_all_deliveries"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </template>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" v-if="type == 'engineer'"></th>
            <th class="simple-table-th" v-if="type == 'engineer'">
              Order Of Del.
            </th>
            <th class="simple-table-th">Delivery #</th>
            <th class="simple-table-th">Title</th>
            <!--   <th class="simple-table-th">Customer</th> -->
            <th class="simple-table-th">Delivery Date</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Assign To</th>
            <th class="simple-table-th">PDF</th>
            <!-- <th class="simple-table-th">Created Time</th> -->
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr class="alternate-listing-row" :key="index">
                  <td v-if="type == 'engineer'" style="width: 30px !important">
                    <v-checkbox
                      dense
                      v-model="selectedRows"
                      :disabled="
                        row.status == 11 || row.status == 1 ? false : true
                      "
                      v-bind:value="row"
                      color="cyan"
                      class="hide-margin"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td v-if="type == 'engineer'" style="width: 90px !important">
                    <v-text-field
                      v-if="selectedRows.includes(row)"
                      dense
                      filled
                      placeholder="Order"
                      v-model="row.order_no"
                      solo
                      flat
                      color="cyan"
                      :disabled="!selectedRows.includes(row)"
                      :error="isDuplicateOrder(row)"
                      @blur="validateOrderNo(row)"
                    />
                    <p class="m-0 custom-nowrap-ellipsis" v-else>
                      <v-chip
                        v-if="row.order_no"
                        class="font-weight-700"
                        small
                        color="black"
                        label
                        text-color=""
                        outlined
                      >
                        {{ row.order_no }}
                      </v-chip>
                    </p>
                    <em
                      v-if="!row.order_no && !selectedRows.includes(row)"
                      class="text-muted"
                    >
                      no order no</em
                    >
                  </td>
                  <td v-on:click="detailDelivery(row)">
                    <!--   <pre> {{ selectedRows }}</pre> -->
                    <p class="m-0 custom-nowrap-ellipsis">
                      <Barcode
                        small
                        :barcode="row?.barcode"
                        :id="row?.id"
                      ></Barcode>
                    </p>
                    <div class="text-capitalize">
                      <v-chip
                        :class="row?.type == 'transfer' ? '' : 'text-white'"
                        label
                        :color="getColor(row?.type)"
                        small
                      >
                        <v-icon small> {{ getIcon(row?.type) }}</v-icon>
                        {{ row?.type }}
                      </v-chip>
                    </div>
                  </td>
                  <td v-on:click="detailDelivery(row)">
                    <div class="m-0 custom-nowrap-ellipsis d-flex">
                      <template v-if="row?.title">
                        <div class="my-auto ml-1">
                          <read-more
                            class="custom-read-more font-size-16 pb-1"
                            more-str="read more"
                            :text="row?.title || '<em>No Title</em>'"
                            link="#"
                            less-str="read less"
                            :max-chars="70"
                          >
                          </read-more>
                        </div>
                      </template>
                      <em class="text-muted" v-else> no title </em>
                    </div>
                  </td>
                  <td v-on:click="detailDelivery(row)">
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 custom-nowrap-ellipsis-two-line text-capitalize"
                      >
                        <b>Date: </b> {{ formatDate(row.started_at) }}
                      </p>
                      <p
                        class="m-0 custom-nowrap-ellipsis-two-line text-capitalize"
                      >
                        <b>Time: </b>{{ formatDateTimeStart(row.started_at) }} -
                        {{ formatetimedata(row.finished_at) }}
                      </p>
                    </div>
                  </td>
                  <td v-on:click="detailDelivery(row)">
                    <div class="custom-nowrap-ellipsis">
                      <div class="mb-1 line-height-initial">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="my-auto custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10"
                              :color="row.qt_status_color"
                              :text-color="row.qt_status_textcolor"
                              label
                              small="small"
                            >
                              <template
                                v-if="
                                  row?.status == 7 && row?.type == 'incoming'
                                "
                              >
                                Returned
                              </template>
                              <template
                                v-else-if="
                                  row?.status == 7 && row?.type == 'transfer'
                                "
                              >
                                Transferred
                              </template>
                              <template v-else>
                                {{ row.qt_status_text }}
                              </template>
                            </v-chip>
                          </template>
                          <span>Status</span>
                        </v-tooltip>
                      </div>
                      <div class="line-height-initial">
                        <v-chip
                          v-if="row?.is_exchange == 1"
                          label
                          :color="'red'"
                          class="my-auto"
                          small
                          outlined
                        >
                          Exchange
                        </v-chip>
                        <v-chip
                          v-if="row?.is_repair == 1"
                          label
                          :color="'red'"
                          class="my-auto"
                          small
                          outlined
                        >
                          Repair
                        </v-chip>
                      </div>
                      <CustomStatus
                        v-if="false"
                        small
                        :status="row?.status"
                        endpoint="delivery/status"
                      ></CustomStatus>
                    </div>
                  </td>
                  <td v-on:click="detailDelivery(row)">
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 max-content-width text-truncate font-weight-700 text-capitalize"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.delivery_engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.delivery_engineers) }}
                      </p>
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!row.pdf_url"
                          color="red"
                          v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                          >mdi-file-pdf</v-icon
                        >
                      </template>
                      <span>Download PDF</span>
                    </v-tooltip>
                  </td>
                  <td class="simple-table-td" v-if="false">
                    <TableActivity :data="row">
                      <template v-slot:date_time>
                        {{ formatDateTime(row?.added_at) }}
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDate(row?.added_at) }}
                        {{ formatTime(row?.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no delivery at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <Dialog :common-dialog="confirm_dialog" :dialog-width="750">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <p class="font-weight-600 font-size-19 text-center">
              Are you sure, you want to
              <span class="font-weight-700">update delivery orders</span>?
            </p>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirm_submit()"
          :disabled="confirm_loading"
          :loading="confirm_loading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { ErrorEventBus } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import moment from "moment";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      selectedRows: [],
      dates: [],
      status: null,
      search: null,
      statusList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeoutLimit: 500,
      confirm_loading: false,
      confirm_dialog: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "customer",
    },
    equipmentId: {
      type: Number,
      default: 0,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    saleId: {
      type: Number,
      default: 0,
    },
    rentalId: {
      type: Number,
      default: 0,
    },
    visitId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {},
  components: {
    CustomStatus,
    TableActivity,
    Dialog,

    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    confirm_submit() {
      if (this.selectedRows.length == 0) {
        return false;
      }
      const allOrderNoNull = this.selectedRows.every((row) => !row.order_no);

      if (allOrderNoNull) {
        ErrorEventBus.$emit("update:error", "Enter atleast one order no.");
        return;
      }

      this.confirm_loading = true;

      let payload = this.lodash.map(this.selectedRows, function (row) {
        return { id: row.id, order_no: row.order_no };
      });

      this.$store
        .dispatch(PATCH, {
          url: "delivery-order-update",
          data: { deliveryOrders: payload },
        })
        .then(() => {
          this.get_all_deliveries();
          this.confirm_loading = false;
          this.confirm_dialog = false;
          this.selectedRows = [];
        })
        .catch((error) => {
          this.logError(error);
          this.confirm_loading = false;
        })
        .finally(() => {
          this.confirm_loading = false;
        });
    },

    isDuplicateOrder(currentRow) {
      if (!currentRow.order_no) {
        return false;
      }
      return this.dataRows.some(
        (row) =>
          row !== currentRow &&
          this.formatDate(row.started_at) ===
            this.formatDate(currentRow.started_at) &&
          row.order_no === currentRow.order_no
      );
    },
    validateOrderNo(row) {
      if (this.isDuplicateOrder(row)) {
        this.$set(row, "order_no", null);
      }
      this.get_all_deliveries();
    },
    clearSelections() {
      this.selectedRows = [];
    },
    detailDelivery(data) {
      let routeName = null;
      if (data?.type == "incoming") {
        routeName = "incoming-delivery.detail";
      } else if (data?.type == "outgoing") {
        routeName = "outgoing-delivery.detail";
      } else if (data?.type == "transfer") {
        routeName = "transfer-delivery.detail";
      }
      this.$router.push(
        this.getDefaultRoute(routeName, {
          params: {
            id: data?.id,
          },
        })
      );
    },
    getColor(type) {
      if (type == "incoming") {
        return "incoming-color white--text";
      } else if (type == "outgoing") {
        return "outgoing-color white--text";
      } else if (type == "transfer") {
        return "transfer-color";
      }
    },
    getIcon(type) {
      if (type == "incoming") {
        return "mdi-transfer-down";
      } else if (type == "outgoing") {
        return "mdi-transfer-up";
      } else if (type == "transfer") {
        return "mdi-transfer";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row).format("hh:mm A");
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
        case "status":
          _this.status = null;
          break;
      }
      _this.get_all_deliveries();
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned.";
      } else {
        return display_name.join(", ");
      }
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    get_all_deliveries() {
      const _this = this;

      let filter = {
        status: this.status,
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        customer: this.customerId,
        rental: this.rentalId,
        sale: this.saleId,
        visit: this.visitId,
        engineer: this.engineerId,
        dates: this.dates,
        search: this.search,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "internal/delivery",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    //_this.get_options();
    _this.get_all_deliveries();
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_deliveries();
    });

    /*   _this.$nextTick(() => {
      _this.equipment.customer = _this.equipmentId;
    }); */
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
    show_order() {
      return this.selectedRows.length > 0 ? true : false;
    },
    hasDuplicateOrders() {
      const validOrders = this.dataRows
        .filter((row) => row.order_no)
        .map((row) => `${row.order_no}-${row.started_at}`);
      const uniqueOrders = new Set(validOrders);
      return uniqueOrders.size !== validOrders.length;
    },
  },
};
</script>
