<template>
  <CreateUpdateTemplate
    actionCols="3"
    headerCols="9"
    :routePreventDialog="routePreventDialog"
    :customClass="'job-ctx-create create-form'"
    v-if="getPermission('delivery:create')"
  >
    <template v-slot:header-title>
      <div class="form-action">
        <div
          class="form-title d-flex"
          :class="
            deliveryId > 0 || $route?.query?.duplicateType
              ? 'pointer-events-none'
              : ''
          "
        >
          {{ getTitle() }}

          <div
            v-if="vCustomerId"
            class="ml-3 form-title-link cursor-pointer text-h5 d-flex"
            v-on:click="openDialog('customer')"
          >
            {{ vCustomer?.display_name }}
            <div
              v-if="jobCreate?.type == 'transfer'"
              style="
                font-weight: 700;
                color: #24326d;
                font-size: 24px;
                margin-left: 12px;
              "
            >
              To
            </div>
          </div>
          <template v-else>
            <div
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              Select a Customer or Supplier
              <v-icon large color="cyan">mdi-account-check-outline</v-icon>
            </div>
            <div class="mx-3" v-if="jobCreate?.type == 'transfer'">To</div>

            <!--    <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Customer
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div> -->
          </template>
          <template v-if="jobCreate?.type == 'transfer'">
            <div
              v-if="vToCustomerId"
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openToDialog('customer')"
            >
              {{ vToCustomer?.display_name }}
            </div>
            <template v-else>
              <div
                class="ml-3 form-title-link cursor-pointer text-h5"
                v-on:click="openToDialog('customer')"
              >
                Select a Customer or Supplier
                <v-icon large color="cyan">mdi-account-check-outline</v-icon>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:header-action>
      <v-chip
        v-if="jobOption?.rental?.barcode"
        label
        color="chip-custom-blue"
        outlined
        class="text-white p-3 mr-4"
        style="font-size: 21px !important; font-weight: 700"
      >
        <template>
          {{ jobOption?.rental?.barcode }}
        </template>
      </v-chip>
      <v-chip
        v-if="jobOption?.sell?.barcode"
        label
        color="chip-custom-blue"
        outlined
        class="text-white p-3 mr-4"
        style="font-size: 21px !important; font-weight: 700"
      >
        <template>
          {{ jobOption?.sell?.barcode }}
        </template>
      </v-chip>
      <v-chip
        v-if="jobOption?.visit?.barcode"
        label
        color="chip-custom-blue"
        outlined
        class="text-white p-3 mr-4"
        style="font-size: 21px !important; font-weight: 700"
      >
        <template>
          {{ jobOption?.visit?.barcode }}
        </template>
      </v-chip>
      <v-chip
        v-if="jobOption?.delivery?.barcode"
        label
        color="chip-custom-blue"
        outlined
        class="text-white p-3 mr-4"
        style="font-size: 21px !important; font-weight: 700"
      >
        <template>
          {{ jobOption?.delivery?.barcode }}
        </template>
      </v-chip>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || deliveryLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || deliveryLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="validateJob()"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="jobForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="validateJob()"
      >
        <div class="delivery-padding">
          <v-row>
            <v-col cols="7" class="pb-0">
              <div>
                <label class="font-weight-600 font-size-18 required pl-1"
                  >Delivery Title</label
                >

                <TextField
                  id="delivery-title"
                  dense
                  filled
                  placeholder="Delivery Title"
                  solo
                  flat
                  counter="50"
                  v-model="jobCreate.title"
                  color="cyan"
                  :maxlength="250"
                  :rules="[
                    validateRules.required(jobCreate.title, 'Delivery Title'),
                  ]"
                />
                <TextAreaField
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  v-model="jobCreate.description"
                  placeholder="Description"
                  solo
                  flat
                  row-height="25"
                  counter="250"
                />
              </div>
              <v-row class="pl-1">
                <v-col md="6" v-if="jobCreate?.type == 'transfer'">
                  <v-layout class="align-center">
                    <v-flex md12>
                      <v-checkbox
                        color="green"
                        class="p-0"
                        style="padding-left: 0px !important"
                        label="Billing From Customer"
                        v-model="jobCreate.select_billing_type"
                        :value="'from_customer_billing'"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6" v-if="jobCreate?.type == 'transfer'">
                  <v-layout
                    class="align-center"
                    v-if="jobCreate?.type == 'transfer'"
                  >
                    <v-flex md12>
                      <v-checkbox
                        color="green"
                        class="p-0"
                        style="padding-left: 0px !important"
                        label="Billing To Customer"
                        v-model="jobCreate.select_billing_type"
                        :value="'to_customer_billing'"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
              <v-row class="pl-1">
                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  class="bgfrom"
                >
                  <v-layout class="align-center">
                    <v-flex md11>
                      <label class="my-0 address-heading">
                        <template v-if="jobCreate?.type == 'transfer'">
                          From </template
                        >Billing Location
                      </label></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        v-on:click="openDialog('billing', 'from_customer')"
                        small
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBilling?.id">
                    <div></div>
                    <span v-if="vBilling && vBilling.property_address">
                      <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                    >
                    <template v-if="vBilling?.withcompany == 1">
                      <v-chip label small color="red" outlined>
                        A&G
                      </v-chip></template
                    >
                    <p
                      v-if="vBilling && vBilling.property_address"
                      class="mb-0"
                    >
                      <template v-if="vBilling.property_name">
                        {{ vBilling.property_name }}<br />
                      </template>
                      {{ vBilling.street_1 }},
                      <br v-if="vBilling.street_2 || vBilling.unit_no" />
                      <template v-if="vBilling.street_2">
                        {{ vBilling.street_2 }},
                      </template>
                      <template v-if="vBilling.unit_no">
                        {{ vBilling.unit_no }},
                      </template>
                      <br />
                      {{ vBilling.country }}
                      <template
                        v-if="vBilling && vBilling.zip_code != '000000'"
                      >
                        , {{ vBilling.zip_code }}
                      </template>
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      billing location
                    </p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      customer / supplier
                    </p>
                  </template>
                </v-col>
                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  class="bgfrom"
                  :class="jobCreate?.type == 'transfer' ? 'roborder' : ''"
                >
                  <v-layout class="align-center">
                    <v-flex md11
                      ><label class="my-0 address-heading"
                        ><template v-if="jobCreate?.type == 'transfer'">
                          From
                        </template>
                        {{ getSiteAddressTitle() }}</label
                      ></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property', 'from_customer')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vProperty?.id">
                    <span v-if="vProperty && vProperty.property_address">
                      <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                    >
                    <p
                      class="mr-2 mb-0 text-capitalize"
                      v-if="vProperty && vProperty.property_address"
                    >
                      <template v-if="vProperty.property_name">
                        {{ vProperty.property_name }}<br />
                      </template>
                      {{ vProperty.street_1 }},
                      <br v-if="vProperty.street_2 || vProperty.unit_no" />
                      <template v-if="vProperty.street_2">
                        {{ vProperty.street_2 }},
                      </template>
                      <template v-if="vProperty.unit_no">
                        {{ vProperty.unit_no }},
                      </template>
                      <br />
                      {{ vProperty.country }}
                      <template
                        v-if="vProperty && vProperty.zip_code != '000000'"
                      >
                        , {{ vProperty.zip_code }}
                      </template>
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      {{ getSiteAddressTitle() }}
                    </p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      customer / supplier
                    </p>
                  </template>
                </v-col>
                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  v-if="jobCreate?.type != 'transfer'"
                  class="bgfrom"
                >
                  <v-layout class="align-center">
                    <v-flex md11
                      ><label class="my-0 address-heading"
                        >{{ getPickupAddressTitle() }}
                      </label></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('pic_address')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPicAddress?.id">
                    <span v-if="vPicAddress">
                      <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                    >
                    <template v-if="vPicAddress?.id">
                      <v-chip label small color="red" outlined>
                        A&G
                      </v-chip></template
                    >
                    <p class="mr-2 mb-0 text-capitalize">
                      <template v-if="vPicAddress?.property_name">
                        {{ vPicAddress?.property_name }}<br />
                      </template>
                      {{ vPicAddress.street_1 }},
                      <br v-if="vPicAddress.street_2 || vPicAddress.unit_no" />
                      <template v-if="vPicAddress.street_2">
                        {{ vPicAddress.street_2 }},
                      </template>
                      <template v-if="vPicAddress.unit_no">
                        {{ vPicAddress.unit_no }},
                      </template>
                      <br />
                      {{ vPicAddress.country }}
                      <template
                        v-if="vPicAddress && vPicAddress.zip_code != '000000'"
                      >
                        , {{ vPicAddress.zip_code }}
                      </template>
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select site location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select customer / supplier
                    </p>
                  </template>
                </v-col>
                <template v-if="jobCreate?.type == 'transfer'">
                  <v-col
                    :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                    class="bgto"
                  >
                    <v-layout class="align-center">
                      <v-flex md11
                        ><label class="my-0 address-heading"
                          ><template v-if="jobCreate?.type == 'transfer'">
                            To </template
                          >Billing Location</label
                        >
                      </v-flex>
                      <v-flex md1 class="position-relative">
                        <v-icon
                          v-on:click="openToDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vToBilling?.id">
                      <span v-if="vToBilling && vToBilling.property_address">
                        <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                      >
                      <template v-if="vToBilling?.withcompany == 1">
                        <v-chip label small color="red" outlined>
                          A&G
                        </v-chip></template
                      >
                      <p
                        v-if="vToBilling && vToBilling.property_address"
                        class="mb-0"
                      >
                        <template v-if="vToBilling.property_name">
                          {{ vToBilling.property_name }}<br />
                        </template>
                        {{ vToBilling.street_1 }},
                        <br v-if="vToBilling.street_2 || vToBilling.unit_no" />
                        <template v-if="vToBilling.street_2">
                          {{ vToBilling.street_2 }},
                        </template>
                        <template v-if="vToBilling.unit_no">
                          {{ vToBilling.unit_no }},
                        </template>
                        <br />
                        {{ vToBilling.country }}
                        <template
                          v-if="vToBilling && vToBilling.zip_code != '000000'"
                        >
                          , {{ vToBilling.zip_code }}
                        </template>
                      </p>
                    </template>
                    <template v-else-if="vToCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          To
                        </template>
                        billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        customer / supplier
                      </p>
                    </template>
                  </v-col>
                  <v-col
                    :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                    class="bgto"
                  >
                    <v-layout class="align-center">
                      <v-flex md11
                        ><label class="my-0 address-heading"
                          ><template v-if="jobCreate?.type == 'transfer'">
                            To
                          </template>
                          Dropoff Address</label
                        ></v-flex
                      >
                      <v-flex md1 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openToDialog('property')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vToProperty?.id">
                      <span v-if="vToProperty && vToProperty.property_address">
                        <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                      >
                      <p
                        class="mr-2 mb-0 text-capitalize"
                        v-if="vToProperty && vToProperty.property_address"
                      >
                        <template v-if="vToProperty.property_name">
                          {{ vToProperty.property_name }}<br />
                        </template>
                        {{ vToProperty.street_1 }},
                        <br
                          v-if="vToProperty.street_2 || vToProperty.unit_no"
                        />
                        <template v-if="vToProperty.street_2">
                          {{ vToProperty.street_2 }},
                        </template>
                        <template v-if="vToProperty.unit_no">
                          {{ vToProperty.unit_no }},
                        </template>
                        <br />
                        {{ vToProperty.country }}
                        <template
                          v-if="vToProperty && vToProperty.zip_code != '000000'"
                        >
                          , {{ vToProperty.zip_code }}
                        </template>
                      </p>
                    </template>
                    <template v-else-if="vToCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          To
                        </template>
                        Dropoff Address
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        customer / supplier
                      </p>
                    </template>

                    <v-layout class="mt-3" v-if="false">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="jobCreate.property_contact"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </template>

                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  class="bgfrom"
                >
                  <v-layout class="mt-3 align-center">
                    <v-flex md11
                      ><label class="my-0 address-heading">
                        <template v-if="jobCreate?.type == 'transfer'">
                          From
                        </template>
                        Contact Details</label
                      ></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        small
                        v-on:click="
                          openDialog('billing-contact', 'from_customer')
                        "
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBillingContact?.id">
                    <p class="mb-0">{{ vBillingContact.display_name }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      contact person
                    </p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      customer / supplier
                    </p>
                  </template>
                  <v-layout class="mt-3" v-if="false">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="jobCreate.billing_contact"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  class="bgfrom"
                  :class="jobCreate?.type == 'transfer' ? 'roborder' : ''"
                >
                  <v-layout class="mt-3 align-center">
                    <v-flex md11
                      ><label class="my-0 address-heading"
                        ><template v-if="jobCreate?.type == 'transfer'">
                          From
                        </template>
                        Contact Details</label
                      ></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        small
                        v-on:click="
                          openDialog('property-contact', 'from_customer')
                        "
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPropertyContact?.id">
                    <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      contact person
                    </p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select
                      <template v-if="jobCreate?.type == 'transfer'">
                        from
                      </template>
                      customer / supplier
                    </p>
                  </template>

                  <v-layout class="mt-3" v-if="false">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="jobCreate.property_contact"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col
                  :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                  v-if="jobCreate?.type != 'transfer'"
                  class="bgfrom"
                >
                  <v-layout class="mt-3 align-center">
                    <v-flex md11
                      ><label class="my-0 address-heading"
                        >Contact Details</label
                      ></v-flex
                    >
                    <v-flex md1 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('userdata')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vContactUser?.id">
                    <p class="mb-0">{{ vContactUser?.display_name }}</p>
                    <p class="mb-0">{{ vContactUser?.phone_number }}</p>
                    <p class="mb-0">{{ vContactUser?.user_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">
                      Please select customer / supplier
                    </p>
                  </template>

                  <v-layout class="mt-3" v-if="false">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="jobCreate.property_contact"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <template v-if="jobCreate?.type == 'transfer'">
                  <v-col
                    :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                    class="bgto"
                  >
                    <v-layout class="mt-3 align-center">
                      <v-flex md11
                        ><label class="my-0 address-heading"
                          ><template v-if="jobCreate?.type == 'transfer'">
                            To
                          </template>
                          Contact Details</label
                        ></v-flex
                      >
                      <v-flex md1 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openToDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vToBillingContact?.id">
                      <p class="mb-0">{{ vToBillingContact.display_name }}</p>
                      <p class="mb-0">{{ vToBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vToBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        contact person
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        customer / supplier
                      </p>
                    </template>
                    <v-layout class="mt-3" v-if="false">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="jobCreate.billing_contact"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col
                    :md="jobCreate?.type == 'transfer' ? '3' : '4'"
                    class="bgto"
                  >
                    <v-layout class="mt-3 align-center">
                      <v-flex md11
                        ><label class="my-0 address-heading"
                          ><template v-if="jobCreate?.type == 'transfer'">
                            To
                          </template>
                          Contact Details</label
                        ></v-flex
                      >
                      <v-flex md1 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openToDialog('property-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vToPropertyContact?.id">
                      <p class="mb-0">{{ vToPropertyContact.display_name }}</p>
                      <p class="mb-0">{{ vToPropertyContact.primary_phone }}</p>
                      <p class="mb-0">{{ vToPropertyContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vToCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        contact person
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">
                        Please select
                        <template v-if="jobCreate?.type == 'transfer'">
                          to
                        </template>
                        customer / supplier
                      </p>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col cols="5" class="pb-0">
              <table width="100%">
                <tr v-if="jobCreate?.type">
                  <td colspan="2" width="160" class="font-weight-600">
                    <v-btn
                      depressed
                      tile
                      :disabled="formLoading"
                      :color="getColor()"
                      style="
                        font-size: 16px !important;
                        height: 38px !important;
                        pointer-events: none;
                      "
                      class="mr-2"
                    >
                      <v-icon> {{ getIcon() }}</v-icon>
                      {{ jobCreate.type }}</v-btn
                    >
                    <v-btn
                      v-if="
                        jobCreate.is_exchange == 1 || jobCreate.is_repair == 1
                      "
                      depressed
                      tile
                      :disabled="formLoading"
                      color="red white--text"
                      style="
                        font-size: 16px !important;
                        height: 38px !important;
                        pointer-events: none;
                      "
                    >
                      <template v-if="jobCreate.is_exchange == 1">
                        Exchange
                      </template>
                      <template v-if="jobCreate.is_repair == 1">
                        Repair
                      </template>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="deliveryEditData?.barcode">
                  <td width="160" class="font-weight-600">
                    <label class="ml-1">Delivery Number</label>
                  </td>
                  <td class="font-weight-700 d-flex">
                    <label>{{ deliveryEditData?.barcode }} </label>
                  </td>
                </tr>

                <tr v-if="jobOption.delivery_barcode">
                  <td width="160" class="font-weight-600">
                    <label>Delivery Number</label>
                  </td>
                  <td class="font-weight-700 d-flex">
                    <label class="ml-1"
                      >{{ jobOption.delivery_barcode }}
                    </label>
                  </td>
                </tr>

                <tr v-if="false">
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Payment Type </label>
                  </td>
                  <td>
                    <v-radio-group
                      v-on:change="showPriceData($event)"
                      v-model="jobCreate.payment_type"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio
                        value="prepaid"
                        label="Prepaid"
                        color="cyan"
                      ></v-radio>

                      <v-radio
                        value="postpaid"
                        label="Postpaid"
                        color="red"
                      ></v-radio>
                      <v-radio
                        label="Cash on delivery"
                        value="cash-on-delivery"
                        color="green"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr v-if="false">
                  <td width="160" class="font-weight-600">
                    <label for="payment-mode">Payment Mode</label>
                  </td>
                  <td>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      id="payment-mode"
                      :items="paymentModeList"
                      :disabled="deliveryLoading"
                      :loading="deliveryLoading"
                      v-model.trim="jobCreate.payment_mode"
                      label="Payment Mode"
                      solo
                      flat
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Payment Mode Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td width="160" class="font-weight-600">
                    <label for="reference-no">Reference #</label>
                  </td>
                  <td>
                    <TextField
                      id="delivery-title"
                      dense
                      filled
                      placeholder="Reference #"
                      solo
                      flat
                      counter="25"
                      v-model.trim="jobCreate.reference"
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr v-if="false">
                  <td width="160" class="font-weight-600">
                    <label for="reference-no">Old Delivery</label>
                  </td>
                  <td>
                    <v-text-field
                      id="reference-no"
                      v-model.trim="jobCreate.old_do"
                      dense
                      filled
                      :disabled="deliveryLoading"
                      :loading="deliveryLoading"
                      color="cyan"
                      label="Old Delivery"
                      :rules="[
                        validateRules.minLength(
                          jobCreate.old_do,
                          'Old Delivery',
                          1
                        ),
                        validateRules.maxLength(
                          jobCreate.old_do,
                          'Old Delivery',
                          30
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    />
                  </td>
                </tr>

                <tr>
                  <td width="160" class="font-weight-600">
                    <label for="priority">Priority</label>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="jobCreate.priority"
                      :items="jobPriorityList"
                      item-text="value"
                      item-value="id"
                      item-color="cyan"
                      solo
                      flat
                      filled
                      dense
                      label="Priority"
                      hide-details
                    >
                      <template v-slot:selection="data">
                        <span class="">
                          <v-icon
                            class="mdi-18px"
                            :color="data.item.value == 'High'
                                        ? 'red'
                                        : data.item.value == 'Medium'
                                          ? 'orange'
                                          : data.item.value == 'Low'
                                            ? 'green' : 'cyan',
                                                                                "
                            >mdi-circle</v-icon
                          >
                          {{ data.item.value
                          }}<!--  ({{ data.item.barcode }}) -->
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-icon class="mr-0 my-1">
                            <v-icon
                              class="mdi-18px"
                              :color="data.item.value == 'High'
                                          ? 'red'
                                          : data.item.value == 'Medium'
                                            ? 'orange'
                                            : data.item.value == 'Low'
                                              ? 'green' : 'cyan',
                                                                                  "
                              >mdi-circle</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.value"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>

                <tr v-if="false">
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Show price to delivery partner </label>
                  </td>
                  <td>
                    <v-checkbox
                      color="cyan"
                      :label="
                        jobCreate.payment_type == 'cash-on-delivery'
                          ? 'CANNOT UNCHECKED BECAUSE PAYMENT TYPE IS CASH ON DELIVERY'
                          : null
                      "
                      hide-details
                      :disabled="jobCreate.payment_type == 'cash-on-delivery'"
                      v-model="jobCreate.show_price"
                      class="m-0 p-0"
                    ></v-checkbox>
                  </td>
                </tr>
                <tr>
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Key Given </label>
                  </td>
                  <td>
                    <v-radio-group
                      v-model="jobCreate.key_given"
                      row
                      hide-details
                      class="mt-0"
                    >
                      <v-radio value="yes" label="Yes" color="green"></v-radio>

                      <v-radio value="no" label="No" color="red"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Diesel Level </label>
                  </td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <template
                        v-for="(diesel_level, index) in jobCreate.diesel_level"
                      >
                        <v-checkbox
                          :key="index"
                          class="mr-2"
                          color="cyan white--text"
                          :label="diesel_level.title"
                          v-model="diesel_level.is_active"
                        />
                      </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Machine(s) delivered on </label>
                  </td>
                  <td>
                    <DatePicker
                      solo
                      flat
                      :mandatory="false"
                      :nullable="true"
                      placeholder="Machine(s) delivered on "
                      v-model="jobCreate.machine_delivered_on"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td width="160" class="font-weight-600" valign="top">
                    <label for="tag">Tags </label>
                  </td>
                  <td>
                    <TagAutoComplete type="delivery" v-model="jobCreate.tags" />
                  </td>
                </tr>
              </table>
            </v-col>

            <v-col cols="12" class="job-schedule">
              <v-container fluid class="p-0 pl-1">
                <v-layout>
                  <v-flex md12>
                    <OneOffSchedule
                      :key="`job-one-off-schedule-${scheduleKey}`"
                    ></OneOffSchedule>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>
            <v-col cols="12">
              <v-container fluid class="p-0 pl-1">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text ref="lineItem" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <LineItem
                          is-delivery
                          :item-set="setIteam"
                          :related-type="10"
                          :related-detail="deliveryEditData"
                          :key="`delivery-line-item-${uniqueId}`"
                          module-type="delivery"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <v-container fluid class="p-0 pl-1">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="deliveryLoading"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Terms &amp; Conditions
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-autocomplete
                      id="payment-mode"
                      dense
                      v-model="jobCreate.term_condition_id"
                      :items="jobOption?.term_condition_list"
                      item-text="label"
                      item-color="cyan"
                      item-value="id"
                      class="pl-0 mb-3"
                      filled
                      hide-details
                      placeholder="Select Terms and conditions"
                      solo
                      flat
                      color="cyan"
                      v-on:change="update_term_condition()"
                    />
                    <TinyMCE v-model="jobCreate.term_conditions" />
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container fluid class="p-0 pl-1">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="deliveryLoading"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Client Note
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <TinyMCE v-model="jobCreate.client_remark" />
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container fluid class="p-0 pl-1">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="deliveryLoading"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Admin Note (internal use only)
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <TinyMCE v-model="jobCreate.admin_remark" />
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <v-container fluid class="p-0 pl-1">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="deliveryLoading"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Attachments (internal use only)
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <FileUpload v-model="attachments" :maxLimit="5">
                    </FileUpload>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <!-- </perfect-scrollbar> -->
      </v-form>
      <ToCustomerSupplierSelect
        :dialog="reToCustomerDialog"
        @close="closeToCustomerDialog"
      />
      <CustomerSupplierSelect
        :dialog="reCustomerDialog"
        @close="closeCustomerDialog"
      />
      <PersonSelect
        :key="`job-person-select-${dailogKey}`"
        :dialog="contactDialog"
        v-on:close="contactDialog = false"
        :type="contactType"
      />
      <ToPersonSelect
        :key="`job-to-person-select-${dailogKey}`"
        :dialog="toContactDialog"
        v-on:close="toContactDialog = false"
        :type="contactType"
      />
      <AddressSelect
        :dialog="propertyDialog"
        v-on:close="propertyDialog = false"
        :type="propertyType"
        :label="propertyLabel"
      />
      <ToAddressSelect
        :dialog="toPropertyDialog"
        v-on:close="toPropertyDialog = false"
        :type="propertyType"
        :label="toPropertyLabel"
      />

      <template v-if="clientPicBillingDialog">
        <clientPicBillingDialog
          :label="picLabel"
          disabled-auto-select
          :clientPicBillingDialog="clientPicBillingDialog"
          :customer="vCustomerId"
          v-on:close="closeDialog"
          v-on:selectPickupAddress="selectPickupAddress"
        >
        </clientPicBillingDialog>
      </template>
      <template v-if="usersDialog">
        <usersDialog
          disabled-auto-select
          :usersDialog="usersDialog"
          :customer="vCustomerId"
          v-on:close="closeDialog"
          v-on:selectUsers="selectUsers"
        ></usersDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import {
  QUERY,
  /*  POST,
  PUT,
  GET, */
  /* CLEAR_ERROR, */
} from "@/core/services/store/request.module";
import usersDialog from "@/view/pages/partials/Select-User.vue";
import clientPicBillingDialog from "@/view/pages/partials/Select-client-Pic-address.vue";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import OneOffSchedule from "@/view/pages/delivery/Delivery-Schedule.vue";
import ObjectPath from "object-path";
/* import Dialog from "@/view/pages/partials/Dialog.vue"; */

import { SET_DIALOG_STATUS } from "@/core/services/store/customer.module";
import {
  RESET_STATE,
  RESET_CREATE_STATE,
  SET_PIC_ADDRESS,
  SET_USER_CONTACT,
  SET_CUSTOMER_TYPE,
  SET_CUSTOMER,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
} from "@/core/services/store/visit.module";

import {
  SET_CUSTOMER_DLV_TYPE,
  RESET_TO_CREATE_STATE,
  RESET_TO_STATE,
  CREATE_OR_UPDATE,
  SET_LOADING,
  SET_TO_DIALOG_STATUS,
  GET_DELIVERY,
  SET_FROM_ADDRESS_FLAG,
  SET_TO_ADDRESS_FLAG,
  SET_DELIVERY_TYPE,
  SET_FROM_PAYLOAD_ADDRESS_FLAG,
  SET_TO_PAYLOAD_ADDRESS_FLAG,
  UPDATE_SCHEDULE_STATE,
  UPDATE_DEFAULT_DATE,
  SET_AVAILABLE_ENGINEER,
  SET_OPTION,
} from "@/core/services/store/delivery.module";
import {
  SET_VISIT_LINE_ITEM,
  SET_VISIT_CALCULATION,
  GET_LINE_ITEM,
} from "@/core/services/store/visit.module";
import CustomerSupplierSelect from "@/view/components/CustomerSupplierSelect.vue";
import ToCustomerSupplierSelect from "@/view/components/ToCustomerSupplierSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import ToAddressSelect from "@/view/components/ToAddressSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import ToPersonSelect from "@/view/components/ToPersonSelect.vue";
import DatePicker from "@/view/components/DatePicker.vue";
import LineItem from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileUpload from "@/view/components/app-component/FileInput.vue";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

import { toSafeInteger } from "lodash";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import { mapGetters } from "vuex";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "job-create",
  title: "Create Job",
  data() {
    return {
      setIteam: false,
      routePreventDialog: false,
      /* new code start */
      uniqueId: Number(new Date()),
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      customerDialog: false,
      contactDialog: false,
      propertyDialog: false,
      toContactDialog: false,
      toPropertyDialog: false,
      contactType: null,
      propertyType: null,
      propertyLabel: null,
      toPropertyLabel: null,
      picLabel: null,
      picUserLabel: null,
      /* new code end */
      pic_address_data: {},
      usersData: {},
      picAddress: {},
      formLoading: false,
      /*    deliveryLoading: false, */
      jobCreated: false,
      filter_label: "High",
      barcodeSetting: {},
      tagList: [],
      jobOption: {},
      jobCreate: {
        select_billing_type: "from_customer_billing",
        type: null,
        title: null,
        description: null,
        payment_type: "prepaid",
        payment_mode: 0,
        partial_delivery: false,
        reference: null,
        old_do: null,
        po_number: null,
        priority: 3,
        tags: [],
        barcode: null,
        term_conditions: null,
        term_condition_id: 0,
        client_remark: null,
        admin_remark: null,
        engineer_count: 0,
        machine_delivered_on: null,
        key_given: "yes",
        is_repair: 0,
        is_exchange: 0,
        diesel_level: [
          { title: "1/4", is_active: true },
          { title: "1/2", is_active: false },
          { title: "3/4", is_active: false },
          { title: "Full", is_active: false },
          { title: "N/A", is_active: false },
        ],
      },
      jobPriorityList: [
        {
          id: 1,
          value: "High",
        },
        {
          id: 2,
          value: "Medium",
        },
        {
          id: 3,
          value: "Low",
        },
      ],

      usersDialog: false,
      clientPicBillingDialog: false,
      jobId: null,
      deliveryId: null,
      paymentModeList: [],
      deliveryCreated: false,
      attachments: [],
      duplicateType: null,
      duplicateId: 0,
    };
  },
  components: {
    usersDialog,
    clientPicBillingDialog,
    CreateUpdateTemplate,
    OneOffSchedule,
    /*  Dialog, */
    TinyMCE,
    LineItem,
    FileUpload,
    PersonSelect,
    ToPersonSelect,
    AddressSelect,
    ToAddressSelect,
    CustomerSupplierSelect,
    ToCustomerSupplierSelect,
    TagAutoComplete,
    TextAreaField,
    DatePicker,
  },
  methods: {
    /* new code start */
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.$store.commit(SET_CUSTOMER_DLV_TYPE, "from_customer");
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        if (this.deliveryId > 0) {
          return false;
        }
        if (this.$route?.query?.duplicateType) {
          return false;
        }
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit(
            "update:error",
            "Please Select Customer / Supplier."
          );
          return false;
        }
        if (!this.vCustomerId && this.jobCreate?.type == "transfer") {
          this.scrollToTop();
          ErrorEventBus.$emit(
            "update:error",
            "Please Select From Customer / Supplier."
          );
          return false;
        }
        if (type == "billing") {
          this.$store.commit(SET_FROM_ADDRESS_FLAG, 1);
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.$store.commit(SET_FROM_ADDRESS_FLAG, 0);
          this.propertyType = "property";
          this.propertyLabel = this.getSiteAddressTitle();
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
        if (type == "pic_address") {
          this.clientPicBillingDialog = true;
          this.picLabel = this.getPickupAddressTitle();
        }
        if (type == "userdata") {
          this.usersDialog = true;
        }
      }
    },
    openToDialog(type) {
      this.$store.commit(SET_TO_DIALOG_STATUS, false);
      this.propertyType = null;
      this.toPropertyLabel = null;
      this.toPropertyDialog = false;
      this.contactType = null;
      this.toContactDialog = false;

      if (type == "customer") {
        if (this.deliveryId > 0) {
          return false;
        }
        if (this.$route?.query?.duplicateType) {
          return false;
        }
        this.$store.commit(SET_TO_DIALOG_STATUS, true);
      } else {
        if (!this.vToCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit(
            "update:error",
            "Please Select To Customer / Supplier."
          );
          return false;
        }
        if (!this.vToCustomerId && this.jobCreate?.type == "transfer") {
          this.scrollToTop();
          ErrorEventBus.$emit(
            "update:error",
            "Please Select From Customer / Supplier."
          );
          return false;
        }
        if (type == "billing") {
          this.$store.commit(SET_TO_ADDRESS_FLAG, 1);
          this.propertyType = "billing";
          this.toPropertyLabel = "Billing Location";
          this.toPropertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.toContactDialog = true;
        }
        if (type == "property") {
          this.$store.commit(SET_TO_ADDRESS_FLAG, 0);
          this.propertyType = "property";
          this.toPropertyLabel = this.getToSiteAddressTitle();
          this.toPropertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.toContactDialog = true;
        }
        if (type == "pic_address") {
          this.picLabel = this.getPickupAddressTitle();
          this.clientPicBillingDialog = true;
        }
        if (type == "userdata") {
          this.usersDialog = true;
        }
      }
    },

    closeCustomerDialog() {
      (this.uniqueId = Number(new Date())),
        this.$store.commit(SET_DIALOG_STATUS, false);
    },
    closeToCustomerDialog() {
      this.$store.commit(SET_TO_DIALOG_STATUS, false);
    },
    getIcon() {
      if (this.jobCreate?.type == "incoming") {
        return "mdi-transfer-down";
      } else if (this.jobCreate?.type == "outgoing") {
        return "mdi-transfer-up";
      } else if (this.jobCreate?.type == "transfer") {
        return "mdi-transfer";
      }
    },

    getColor() {
      if (this.jobCreate?.type == "incoming") {
        return "incoming-color white--text";
      } else if (this.jobCreate?.type == "outgoing") {
        return "outgoing-color white--text";
      } else if (this.jobCreate?.type == "transfer") {
        return "transfer-color";
      }
    },
    getTitle() {
      if (this.jobCreate?.type == "incoming") {
        return "Delivery From";
      } else if (this.jobCreate?.type == "outgoing") {
        return "Delivery For";
      } else if (this.jobCreate?.type == "transfer") {
        return "Delivery From";
      }
    },
    getSiteAddressTitle() {
      if (this.jobCreate?.type == "incoming") {
        return "Pickup Address";
      } else if (this.jobCreate?.type == "outgoing") {
        return "Dropoff Address";
      } else if (this.jobCreate?.type == "transfer") {
        return "Pickup Address";
      }
    },
    getToSiteAddressTitle() {
      return "Dropoff Address";
    },
    getPickupAddressTitle() {
      if (this.jobCreate?.type == "incoming") {
        return "Dropoff Address";
      } else if (this.jobCreate?.type == "outgoing") {
        return "Pickup Address";
      } else if (this.jobCreate?.type == "transfer") {
        return "Dropoff Address";
      }
    },

    update_term_condition() {
      let output = this.lodash.find(this.jobOption.term_condition_list, {
        id: this.jobCreate.term_condition_id,
      });
      if (output) {
        this.jobCreate.term_conditions = output.description;
      }
    },
    checkUniqueBarcodes(allEquipment) {
      const seenBarcodes = [];
      for (let i = 0; i < allEquipment.length; i++) {
        const barcode = allEquipment[i].barcode;
        if (seenBarcodes.includes(barcode)) {
          return false;
        }
        seenBarcodes.push(barcode);
      }
      return true;
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Customer / Supplier.")
        );
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Billing Location.")
        );
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Contact Person.")
        );
        return false;
      }

      /*  if (!this.vPropertyId) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Billing Location.")
        );
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Contact Person.")
        );
        return false;
      } */

      if (!this.vToCustomerId && this.jobCreate.type == "transfer") {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select To Customer / Supplier.")
        );
        return false;
      }

      if (!this.vToBillingId && this.jobCreate.type == "transfer") {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select To Billing Location.")
        );
        return false;
      }

      if (!this.vToBillingContactId && this.jobCreate.type == "transfer") {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select To Contact Person.")
        );
        return false;
      }

      if (!this.vPicAddressId && this.jobCreate.type != "transfer") {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Address.")
        );
        return false;
      }
      if (!this.vContactUserId && this.jobCreate.type != "transfer") {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Contact Person.")
        );
        return false;
      }
      if (
        !this.jobCreate?.select_billing_type &&
        this.jobCreate?.type == "transfer"
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please Select Billing Option.")
        );
        return false;
      }
      const allHeaders = this.vSelectedLineItem.every(
        (obj) => obj.type === "header"
      );
      if (
        !this.vSelectedLineItem ||
        !this.vSelectedLineItem.length ||
        allHeaders
      ) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      //title validation
      const AllItems = this.vSelectedLineItem;
      for (var k = 0; k < AllItems.length; k++) {
        if (!AllItems[k].product) {
          ErrorEventBus.$emit(
            "update:error",
            AllItems[k].type + " title is required."
          );
          return false;
        }
      }

      if (
        this.jobCreate?.is_repair == 1 &&
        this.vSelectedLineItem?.length > 0
      ) {
        const allEquipment = this.vSelectedLineItem?.filter(
          (item) => item.type === "equipment"
        ).length;
        if (allEquipment > 1) {
          ErrorEventBus.$emit(
            "update:error",
            "You can choose only one equipment line item for this repair delivery."
          );
          return false;
        }
      }

      if (
        this.jobCreate?.is_exchange == 1 &&
        this.vSelectedLineItem?.length > 0
      ) {
        const allEquipment = this.vSelectedLineItem?.filter(
          (item) => item.type === "equipment"
        );
        if (allEquipment?.length > 2) {
          ErrorEventBus.$emit(
            "update:error",
            "Please select only one new equipment."
          );
          return false;
        }

        if (allEquipment?.length > 0) {
          let unique_id = true;
          unique_id = this.checkUniqueBarcodes(allEquipment);
          if (!unique_id) {
            ErrorEventBus.$emit(
              "update:error",
              "Please choose a different equipment type for this exchange delivery."
            );
            return false;
          }
        }
      }

      const validateStatus = this.$refs.jobForm.validate();

      const formErrors = this.validateForm(this.$refs.jobForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }
      if (validateStatus) {
        this.updateOrCreate();
      }
    },
    async updateOrCreate() {
      try {
        const request = {
          ...this.jobCreate,
          default_started_at: this.defaultStartedAt
            ? this.defaultStartedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          default_finished_at: this.defaultFinishedAt
            ? this.defaultFinishedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          id: this.deliveryId,

          customer: this.vCustomerId,
          property: this.vPropertyId,
          property_contact: this.vPropertyContactId,
          billing: this.vBillingId,
          billing_contact: this.vBillingContactId,
          pic_address:
            this.jobCreate?.type != "transfer"
              ? toSafeInteger(this.vPicAddressId)
              : 0,
          pic_user_id:
            this.jobCreate?.type != "transfer"
              ? toSafeInteger(this.vContactUserId)
              : 0,
          to_customer:
            this.jobCreate?.type == "transfer"
              ? toSafeInteger(this.vToCustomerId)
              : 0,
          to_property:
            this.jobCreate?.type == "transfer"
              ? toSafeInteger(this.vToPropertyId)
              : 0,
          to_property_contact:
            this.jobCreate?.type == "transfer"
              ? toSafeInteger(this.vToPropertyContactId)
              : 0,
          to_billing:
            this.jobCreate?.type == "transfer"
              ? toSafeInteger(this.vToBillingId)
              : 0,
          to_billing_contact:
            this.jobCreate?.type == "transfer"
              ? toSafeInteger(this.vToBillingContactId)
              : 0,
          oneoff: this.deliverySchedule,
          calculation: this.vCalculations,
          "line-item": this.vSelectedLineItem,
          team: this.assignedDeliveryTeam,
          customer_type: this.vCustomerType,
          to_customer_type:
            this.jobCreate?.type == "transfer" ? this.vToCustomerType : null,
          attachments: this.attachments,
          from_billing_wharehouse: this.companyFromPayloadBillingFlag,
          to_billing_wharehouse: this.companyToPayloadBillingFlag,
          convert_id: this.convertId > 0 ? this.convertId : 0,
          convert_type: this.convertType ? this.convertType : null,
          is_visit: this.convertType && this.convertType == "visit" ? 1 : 0,
          is_sell: this.convertType && this.convertType == "sell" ? 1 : 0,
          is_rental: this.convertType && this.convertType == "rental" ? 1 : 0,
          duplicateId: this.$route?.query?.duplicateId,
          duplicateType: this.$route?.query?.duplicateType,

          /*    vPdfAttachments: this.vPdfAttachments, */
        };

        this.$store.dispatch(CREATE_OR_UPDATE, request).then(({ data }) => {
          this.routeDialog = false;
          this.$nextTick(() => {
            let route = "";
            if (this.jobCreate?.type == "incoming") {
              route = "incoming-delivery";
            } else if (this.jobCreate?.type == "outgoing") {
              route = "outgoing-delivery";
            } else if (this.jobCreate?.type == "transfer") {
              route = "transfer-delivery";
            }
            this.deliveryCreated = true;
            let id = data?.id;
            this.$router.push({
              name: `admin.${route}.detail`,
              params: { id },
            });
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    /* new code end */

    showPriceData(data) {
      if (data == "cash-on-delivery") {
        this.jobCreate.show_price = true;
      } else {
        this.jobCreate.show_price = false;
      }
    },

    closeDialog() {
      this.usersDialog = false;
      this.clientPicBillingDialog = false;
    },

    selectPickupAddress(param) {
      this.pic_address_id = param.id;
      this.$store.commit(SET_PIC_ADDRESS, param);
      this.closeDialog();
      this.getPicAddressRelation();
    },
    selectUsers(param) {
      this.pic_user_id = param.id;
      this.$store.commit(SET_USER_CONTACT, param);
      this.closeDialog();
      this.getPicAddressRelation();
      /*  this.getOptions(); */
    },

    getFormattedBilling(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country.name);
      }
      return tempArr.join(", ");
    },
    getOptions() {
      this.$store.commit(SET_LOADING, true);
      this.$store
        .dispatch(QUERY, {
          url: "delivery/options",
          data: {
            pic_address_id: this.vPicAddressId,
            pic_user_id: this.vContactUserId,
            convertId: this.convertId,
            convertType: this.convertType,
            duplicateId: this.duplicateId,
            duplicateType: this.duplicateType,
            deliveryType: this.$route?.query?.type,
            is_exchange: this.$route?.query?.is_exchange ?? 0,
            vExchangeItemId: this.$route?.query?.vExchangeItemId ?? 0,
          },
        })
        .then(({ data }) => {
          this.jobOption = data;
          this.$store.commit(SET_OPTION, this.jobOption);

          if (!this.deliveryId) {
            if (data.barcode) {
              this.jobCreate.barcode = data.barcode;
            }
          }
          let defaultTermConditionObject = this.lodash.find(
            this.jobOption.term_condition_list,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.jobCreate.term_condition_id = defaultTermConditionObject.id;
            this.jobCreate.term_conditions =
              defaultTermConditionObject.description;
          }
          if (data.tags) {
            this.tagList = data.tags;
          }

          if (data.payment_modes) {
            this.paymentModeList = data.payment_modes;
          }
          if (data?.rental && this.convertType == "rental") {
            const dbRental = data?.rental;
            (this.jobCreate.title = dbRental?.title),
              (this.jobCreate.description = dbRental?.description);

            this.$store.dispatch(SET_VISIT_CALCULATION, {
              discount_type: this.lodash.toSafeInteger(dbRental.discount_type),
              discount_value: dbRental.discount_value,
              tax_active: dbRental.tax_applied == 0 ? false : true,
              adjustment: dbRental.adjustment,
              show_price: dbRental.show_price,
              discount_level:
                dbRental.discount_type == 2 ? "line_item" : "transaction",
              tax_value: dbRental.tax_value,
            });
            this.$store.dispatch(SET_VISIT_LINE_ITEM, dbRental?.line_items);
            this.uniqueId = Number(new Date());
            if (this.vSelectedLineItem) {
              this.setIteam = true;
            }
          } else if (data?.sell && this.convertType == "sell") {
            const dbSell = data?.sell;
            (this.jobCreate.title = dbSell?.title),
              (this.jobCreate.description = dbSell?.description);
            this.$store.dispatch(SET_VISIT_CALCULATION, {
              discount_type: this.lodash.toSafeInteger(dbSell.discount_type),
              discount_value: dbSell.discount_value,
              tax_active: dbSell.tax_applied == 0 ? false : true,
              adjustment: dbSell.adjustment,
              show_price: dbSell.show_price,
              discount_level:
                dbSell.discount_type == 2 ? "line_item" : "transaction",
              tax_value: dbSell.tax_value,
            });
            this.$store.dispatch(SET_VISIT_LINE_ITEM, dbSell?.line_items);
            this.uniqueId = Number(new Date());
            if (this.vSelectedLineItem) {
              this.setIteam = true;
            }
          } else if (data?.ticket && this.convertType == "visit") {
            const dbTicket = data?.ticket;
            (this.jobCreate.priority = dbTicket?.priority),
              (this.jobCreate.title = data?.visit?.title),
              (this.jobCreate.description = data?.visit?.instruction);
            const startedAt = data?.visit?.started_at;
            const finishedAt = data?.visit?.finished_at;

            this.$store.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.start_date",
              value: moment(startedAt).format("YYYY-MM-DD"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.end_date",
              value: moment(finishedAt).format("YYYY-MM-DD"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.start_time",
              value: moment(startedAt).format("hh:mm A"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.end_time",
              value: moment(finishedAt).format("hh:mm A"),
            });
            this.$store.dispatch(SET_VISIT_CALCULATION, {
              discount_type: this.lodash.toSafeInteger(dbTicket.discount_type),
              discount_value: dbTicket.discount_value,
              tax_active: dbTicket.tax_applied == 0 ? false : true,
              adjustment: dbTicket.adjustment,
              show_price: dbTicket.show_price,
              discount_level:
                dbTicket.discount_type == 2 ? "line_item" : "transaction",
              tax_value: dbTicket.tax_value,
            });
            this.$store.dispatch(SET_VISIT_LINE_ITEM, data?.ticket_line_items);
            this.uniqueId = Number(new Date());
            if (this.vSelectedLineItem) {
              this.setIteam = true;
            }
          } else if (
            data?.delivery &&
            (this.duplicateType == "rn-do" || this.duplicateType == "do-rn")
          ) {
            this.$store.commit(SET_CUSTOMER, data?.customer ?? {});
            this.$store.commit(SET_PROPERTY, data?.property ?? {});
            this.$store.commit(SET_BILLING, data?.billing ?? {});
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              data?.property_person ?? {}
            );
            this.$store.commit(SET_BILLING_CONTACT, data?.billing_person ?? {});
            const startedAt = data?.delivery?.started_at;
            const finishedAt = data?.delivery?.finished_at;
            this.$store.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.start_date",
              value: moment(startedAt).format("YYYY-MM-DD"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.end_date",
              value: moment(finishedAt).format("YYYY-MM-DD"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.start_time",
              value: moment(startedAt).format("hh:mm A"),
            });

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbOneOffSchedule.end_time",
              value: moment(finishedAt).format("hh:mm A"),
            });

            this.$store.dispatch(SET_VISIT_CALCULATION, {
              discount_type: this.lodash.toSafeInteger(
                data?.delivery?.discount_type
              ),
              discount_value: data?.delivery?.discount_value,
              tax_active: data?.delivery?.tax_applied == 0 ? false : true,
              adjustment: data?.delivery?.adjustment,
              show_price: data?.delivery?.show_price,
              discount_level:
                data?.delivery?.discount_type == 2
                  ? "line_item"
                  : "transaction",
              tax_value: data?.delivery?.tax_value,
            });
            this.$store.dispatch(
              SET_VISIT_LINE_ITEM,
              data?.delivery_line_items
            );
            this.uniqueId = Number(new Date());
            if (this.vSelectedLineItem) {
              this.setIteam = true;
            }
            (this.jobCreate.select_billing_type =
              data?.delivery?.select_billing_type),
              /*  (this.jobCreate.type = data?.delivery?.type), */
              (this.jobCreate.title = data?.delivery?.title),
              (this.jobCreate.description = data?.delivery?.description),
              (this.jobCreate.payment_type = data?.delivery?.payment_type),
              (this.jobCreate.payment_mode = data?.delivery?.payment_mode),
              (this.jobCreate.partial_delivery =
                this.jobCreate.partial_delivery),
              (this.jobCreate.reference = data?.delivery?.reference),
              (this.jobCreate.old_do = data?.delivery?.old_do),
              (this.jobCreate.po_number = data?.delivery?.po_number),
              (this.jobCreate.priority = data?.delivery?.priority),
              (this.jobCreate.tags = data?.tags),
              (this.jobCreate.barcode = data?.delivery?.barcode),
              (this.jobCreate.term_conditions =
                data?.delivery?.term_conditions),
              (this.jobCreate.term_condition_id =
                this.jobCreate.term_condition_id),
              (this.jobCreate.client_remark = data?.delivery?.client_remark),
              (this.jobCreate.admin_remark = data?.delivery?.admin_remark),
              (this.jobCreate.engineer_count = data?.delivery?.engineer_count),
              (this.jobCreate.machine_delivered_on =
                this.jobCreate.machine_delivered_on),
              (this.jobCreate.key_given = data?.delivery?.key_given),
              /*  (this.jobCreate.is_repair = data?.delivery?.is_repair),
              (this.jobCreate.is_exchange = data?.delivery?.is_exchange), */
              (this.jobCreate.diesel_level = data?.delivery?.diesel_level);
            this.jobCreate.diesel_level = data?.delivery?.diesel_level;
            this.jobCreate.diesel_level = data?.delivery?.diesel_level;
            this.$store.commit(
              SET_AVAILABLE_ENGINEER,
              data?.available_team ?? []
            );

            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbAssignedTeamObject",
              value: data?.team,
            });
            this.$store.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbAssignedTeam",
              value: data?.team?.map((row) => row.engineer_id),
            });
          }
          if (
            (data?.sell && this.convertType == "sell") ||
            (data?.rental && this.convertType == "rental") ||
            (data?.visit && this.convertType == "visit")
          ) {
            this.$store.commit(SET_CUSTOMER, data?.customer ?? {});
            this.$store.commit(SET_PROPERTY, data?.property ?? {});
            this.$store.commit(SET_BILLING, data?.billing ?? {});
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              data?.property_person ?? {}
            );
            this.$store.commit(SET_BILLING_CONTACT, data?.billing_person ?? {});
          }

          this.$store.commit(
            SET_PIC_ADDRESS,
            ObjectPath.get(data, "pic_address", {})
          );
          this.$store.commit(
            SET_USER_CONTACT,
            ObjectPath.get(data, "usersData", {})
          );
          if (
            this.convertId > 0 &&
            this.convertType == "rental" &&
            this.jobCreate?.type == "outgoing" &&
            this.deliveryId == 0
          ) {
            this.$store
              .dispatch(GET_LINE_ITEM, {
                type: "equipment",
                customer: this.vCustomerId,
                module: "delivery",
                is_self_visit: false,
                validated_date: this.deliverySchedule?.start_date,
                deliveryType: "outgoing",
              })
              .then(() => {
                if (this.vLineItem?.length == 0) {
                  this.$store.dispatch(SET_VISIT_LINE_ITEM, []);
                  this.uniqueId = Number(new Date());
                }
              });
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.$store.commit(SET_LOADING, false);
        });
    },
    getDelivery() {
      this.$store
        .dispatch(GET_DELIVERY, {
          delivery: this.deliveryId,
        })
        .then(() => {
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Delivery", route: "delivery" },
            { title: "Update" },
            { title: this.deliveryEditData?.barcode },
          ]);

          this.jobCreate = {
            type: this.deliveryEditData?.type,
            priority: this.deliveryEditData?.priority,
            title: this.deliveryEditData?.title,
            description: this.deliveryEditData?.description,
            payment_type: this.deliveryEditData?.payment_type,
            barcode: this.deliveryEditData?.barcode,
            payment_mode: this.deliveryEditData?.payment_mode,
            reference: this.deliveryEditData?.reference,
            diesel_level: this.deliveryEditData?.diesel_level,
            machine_delivered_on: this.deliveryEditData?.machine_delivered_on,
            key_given: this.deliveryEditData?.key_given,
            old_do: this.deliveryEditData?.old_do,
            term_condition_id: this.deliveryEditData?.term_condition_id,
            term_conditions: this.deliveryEditData?.term_conditions,
            client_remark: this.deliveryEditData?.client_remark,
            admin_remark: this.deliveryEditData?.admin_remark,
            tags: this.deliveryEditData?.tags,
            select_billing_type: this.deliveryEditData?.select_billing_type,
            attachments: [],
            is_repair: this.deliveryEditData?.is_repair,
            is_exchange: this.deliveryEditData?.is_exchange,
          };
          this.$store.commit(SET_DELIVERY_TYPE, this.deliveryEditData?.type);
          this.$store.commit(
            SET_CUSTOMER_TYPE,
            this.deliveryEditData?.customer_type
          );
          this.$store.dispatch(
            SET_FROM_PAYLOAD_ADDRESS_FLAG,
            this.deliveryEditData?.from_billing_wharehouse
          );
          if (this.deliveryEditData?.type == "transfer") {
            this.$store.dispatch(
              SET_TO_PAYLOAD_ADDRESS_FLAG,
              this.deliveryEditData?.to_billing_wharehouse
            );
          }
        })
        .catch(() => {
          /* this.goBack(); */
        })
        .finally(() => {
          //
        });
    },

    getPicAddressRelation() {
      this.$store.commit(SET_LOADING, true);
      this.$store
        .dispatch(QUERY, {
          url: "delivery/options",
          data: {
            pic_address_id: this.vPicAddressId,
            pic_user_id: this.vContactUserId,
          },
        })
        .then(({ data }) => {
          this.$store.commit(
            SET_PIC_ADDRESS,
            ObjectPath.get(data, "pic_address", {})
          );
          this.$store.commit(
            SET_USER_CONTACT,
            ObjectPath.get(data, "usersData", {})
          );
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.$store.commit(SET_LOADING, false);
        });
    },
  },
  mounted() {
    this.getOptions();

    if (this.deliveryId > 0) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Delivery", route: "delivery" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Delivery", route: "delivery" },
        { title: "Create" },
      ]);
    }

    if (this.deliveryId == 0) {
      this.jobCreate.type = this.$route?.query?.type;
      this.jobCreate.is_repair = toSafeInteger(this.$route?.query?.is_repair);
      this.jobCreate.is_exchange = toSafeInteger(
        this.$route?.query?.is_exchange
      );
      this.$store.commit(SET_DELIVERY_TYPE, this.jobCreate.type);
      if (!this.jobCreate.type) {
        this.$router.go(-1);
      }
    }

    if (this.deliveryId) {
      this.getDelivery();
    }
  },
  beforeMount() {
    this.deliveryId = toSafeInteger(this.$route.params.id);
    this.convertType = this.$route?.query?.convertType;
    this.convertId = toSafeInteger(this.$route?.query?.convertId);
    this.duplicateType = this.$route?.query?.duplicateType;
    this.duplicateId = toSafeInteger(this.$route?.query?.duplicateId);
  },
  computed: {
    ...mapGetters([
      "vCalculations",
      "vSelectedLineItem",
      "deliverySchedule",
      "assignedDeliveryTeam",
      "reCustomerDialog",
      "reToCustomerDialog",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vContactUserId",
      "vPicAddressId",
      "vContactUser",
      "vPicAddress",
      "vCustomerType",
      "vToDelivery",
      "vToCustomerId",
      "vToPropertyId",
      "vToPropertyContactId",
      "vToBillingId",
      "vToBillingContactId",
      "vToCustomer",
      "vToProperty",
      "vToPropertyContact",
      "vToBilling",
      "vToBillingContact",
      "vToCustomerType",
      "deliveryLoading",
      "deliveryEditData",
      "companyFromPayloadBillingFlag",
      "companyToPayloadBillingFlag",
      "vLineItem",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  beforeCreate() {
    /*     this.$store.dispatch(CLEAR_CUSTOMER); */
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(RESET_TO_STATE);
    this.$store.dispatch(RESET_TO_CREATE_STATE);
  },
  destroyed() {
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(RESET_TO_STATE);
    this.$store.dispatch(RESET_TO_CREATE_STATE);
    /*     this.$store.dispatch(CLEAR_CUSTOMER); */
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.deliveryCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
